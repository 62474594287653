import React from "react";
import RakuyoriHeader from "../../components/rakuyoriheader";
import Sidebar from "../../components/sidebar";
import TopImage from "../../img/concept_top.jpeg"

const ToBiginer = () => {
    return(
        <div className="rakuyori_back">
            <div className="main">
                <RakuyoriHeader />
                <div className="top">
                    <img src={TopImage} />
                </div>
                <div className="concept_body">
                    <div className="to_biginer">
                        <h2>初めてご参加される方へ</h2>
                        <p>○カップリングパーティーまでの流れ</p>
                        <div className="biginer_int">
                            <p>１．スケジュールのページで参加したいカップリングパーティーを選びます。登録ページにて必要事項をご記入ください。</p>
                        </div>
                        <div className="biginer_int">
                            <p>２．参加したいカップリングパーティーをクリックして、受付フォームで必要事項を入力して送信してください。</p>
                        </div>
                        <div className="biginer_int">
                            <p>３．カップリング パーティーで素敵な出会いを。
                                参加者皆さまの安心安全の為、公的な身分証明書にてご本人確認をさせていただいていますので、当日は必ずご持参ください。身分証明書が無い場合はカップリングパーティーに参加できないこともありますのでご注意ください。
                                <br />※必ず規約をお読みいただいてご参加いただきますようお願い致します。</p>
                        </div>
                        <div className="biginer_int">
                            <p><span style={{fontWeight: "bold"}}>□申し込み方法</span><br />
                                受付で必要事項とご本人確認および連絡先が間違いないかの確認を行います。<br />
                                （入会金・登録費・年会費は無料）　<br />
                                安心安全の為、ご面倒とは思いますがよろしくお願い致します。LinkIcon（個人情報保護方針について）</p>
                        </div>
                        <div className="biginer_int">
                            <p><span style={{fontWeight: "bold"}}>□インターネットでのお申し込み</span><br />
                            ※必ず返信メールが届きますので、返信がない場合は、迷惑メール等の設定を必ずされていますので完全に解除して再度お申し込み下さい。</p>
                            <div className="biginer_coution">
                                <p>受付フォームに沿って、必要事項をご記入ください。<br />
                                    エントリー結果を送信致します。<br /><br />
                                    受付後、当日、パーティー会場にて、ご本人確認ができる顔写真付きの公的身分証明書（運転免許証・パスポートなど）をお持ちください。
                                </p>
                            </div>
                        </div>
                    </div>
                   <Sidebar />
                </div>
            </div>
        </div>
    )
}

export default ToBiginer;