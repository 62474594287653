import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React, { useState } from "react";
import RakuyoriHeader from "../../components/rakuyoriheader";
import Sidebar from "../../components/sidebar";
import TopImage from "../../img/concept_top.jpeg"

const Questions = () => {
    const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
    return(
        <div className="rakuyori_back">
            <div className="main">
                <RakuyoriHeader />
                <div className="top">
                    <img src={TopImage} />
                </div>
                <div className="small_space_module" />
                <div className="concept_body">
                    <div className="questions_list">
                        <h2>ラクよりへの質問</h2>
                        <p>質問部分をクリックすると回答がご覧いただけます。</p>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>1 初めて参加しても大丈夫なの</Typography>
                                    <Typography>2 独りで参加するのが不安なのですが</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                まとめて回答 <br/>
                                ９割以上の方が始めての参加です、また、男女共に最初は一人での参加が多いですね。
                                ２･３人の友人同士でご参加も各パーティーにいらっしゃることもあります。
                                最高は５人組で来られた友人同士の方々がいらっしゃいました。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>3 お見合い・お見合いパーティーとカップリングパーテイーとどう違うの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            私たちの考えと、商売で開催されているパーティーの違いがまずあります。
                            いわゆる婚活と言うからには結婚相手を見つけるのが商用では多いようですね。
                            私たちが提供しているパーティーは、山口県庁こども未来課にある「やまぐち結婚応援団」の趣旨に沿い、
                            出会いのきっかけを提供することのみと断言しても良いです。
                            偶然にその場に居る方と話してみて偶然仲良くなるという自然な流れを提供するパーティーであって、
                            この人とあの人をくっ付けたり、こんな職業の人や、年齢がどうこうという、
                            参加者の希望に合う相手を用意することはしておりません。
                            例えば、日ごろ仕事で毎日遅くまで仕事をし、朝早くから出勤、休みの日曜日は、午後まで寝て、ゲームなどで遊び、
                            友人に誘われたら食事に行くという生活習慣の中に、「１２月２４日に宇部のレストランでイベントがありますので来ませんか？」
                            と単なるチラシが目に止まっただけだと思ってます。行かなくても良いですし、なんかあるから良く分からないけど飯が食えるなら行こうかな！
                            ということで、一歩足を踏み出してもらうだけのイベントを私たちは提供しているつもりです。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>4 パーティーの時間は、どのくらいあるの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            数によります。男女合わせて１０名ぐらいであれば、
                            必ず食事する時間がありますので平均２時間～３時間です。４０名で、平均３時間くらいです。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>5 パーティーには、どのような方が参加していますか</Typography>
                                    <Typography>6 何歳ぐらいの方が来られるの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            まとめて回答<br />よく質問があるのが、年齢や出身地の質問が多いですが、私たちは
                            「県内の独身の若者で男性は２０歳～４５歳までを基本とし、
                            女性は２０歳～４０歳までを基本として県内各地から募集しています」として、
                            参加者が集ってこられます。年齢を選びませんし、出身地も選ばないのでその会場ごと全て違っています。
                            実例ですが下関会場に岩国や阿武郡の方なども男女共に来られます。周南会場には、
                            下関や長門の方も来られたりしております。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>7 パーティーの内容は、どんな感じなの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            食事をしながら全ての人と平等に話をしてもらい、表に立たせたり発表など目立たせることは一切しません。
                            ちなみに最初から最後の帰宅まで相手の名前や住所、連絡先など自分も含め判らない仕組みにしてあります。
                            カップルだけには当事者どおしだけわかる仕組みにもなっています。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>8 男女料金が違うのはなぜ</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            私たちのパーティーはボランティアで運営されており、県や市から補助金なども貰わず自費で運営しています。
                            過去に県の事業ということで男女同じ料金にしたことや、女性の料金を極めて安くしたこともありますが、
                            実際には参加者に偏りがでることが判明し、もっとも内容の品質を保ち、参加しやすい料金体制を確立させて今の価格となっております。
                            当会のパーティーは必ず会場ごとのスペシャル料理の量が多く出る様に協力会場の方々へお願いしております。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>9 みんなパーティー開始のどれくらい前に集まるの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            時間どおりに始める為、１０分前がほとんどです。ギリギリに来られると手続に時間が掛かり、
                            置いてきぼりになった雰囲気がやはりあるようです。皆さんからも注目される欠点もあるようです。
                            早い方は１時間前には来られて私たちに悩みや話すコツなどを聞かれてこられる方も多いですね。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>10 どうして予約制なの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            予約の最も重要な目的は料理の注文です。自然な会話と、一人きりになら無い為の工夫に食事を用意しています。
                            話すのが苦手でも食事をしていれば時間が紛らわせます。料理もメイン料理からデザートなどバラエティーに富んでますので、
                            異性と話をしなくても楽しめる仕組みになってます。その為料理は重要で、
                            お菓子やジュース程度なら当日でも対応できるでしょが、至れり尽くせりのおもてなしをしたいと私たちは考えていますので、
                            予約が無いと人数確定ができない為です。あとは、防犯の為です。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>11 いつ、どこで開かれているのですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            一昨年は、月に２回程度と考えて活動していましたが、県内の若者の声を聞くと、
                            第２日曜は休みではないとか、その日だけは仕事とか意見があり、昨年より毎月、
                            ほぼ毎週県内のどこかで開催しようと活動スケジュールを変えました。
                            この場合、今週末は仕事で出れないけど来週末は、
                            遠いけどパーティーがあるので行く気があればいつでも参加できるという環境を整えました。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>12 どのような食事がでるの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            各パーティー会場は、地域や県内、国内でも有名なところが多く、通常なら土曜の夜や、日曜日の昼など、
                            商売の邪魔になったりするんじゃないかと恐縮しながらお願いしているのですが、各会場の方々は、
                            ボランティアで県の趣旨、私たちの目的が商売でないこと、誰かがやらなければ変な商売で被害にあったり、
                            如何わしいと言われることになりかねないとして、全面協力で私たちを応援して頂いております。
                            各会場のこれを食べて欲しいという一品から、雰囲気にあう飲み物や、デザイン、ケーキでメッセージを添えられる会場や、
                            お土産まで用意してくれるところなど様々です。主催者としては払う料金も安いし、長い時間貸し切るし、料理には注文つけるし、
                            あれ出せこれ出せとうるさいとは思いますが、本当に協力してくれて協力してもらっている会場にとっては、
                            週末の稼ぎ時と、この活動を天秤にかけて私たちの活動を採用してもらっていて、とてもありがたく感謝感謝で、頭が上がりません。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>13 ひとり何分ぐらい話すの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            全ての人と、自然に、無理なく、強制されることなく自主性に任せて話をしてもらう仕組みがあるのですが、
                            過去の参加女性のコメントが一番伝わると思うので記載します。「仕事で多くの患者さんがくるので、結構しゃべっていますが、
                            今日のパーティで楽しい会話を１年分しました。喉が渇いたのと、疲れました。」ほとんどの方が苦痛なく、
                            笑顔だけの会話をされているので時間は少なく感じるみたいですね。日ごろ喋らない方や話すのが苦手な方が、
                            ３時間のパーティーで２時間半はしゃべっていると思いますよ。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>14 都合で、キャンセルとかできますか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            前日までは自由にキャンセルしてください。但し当日はキャンセルは出来ません。料理の関係等で当日の場合は、
                            参加料全額キャンセル料として支払っていただきます。その為に、前日までには必ず電話やメールにて出欠確認をして、
                            返答をもらっています、当日キャンセルは私たちには理解出来ないことと考えています。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>15 話すのが苦手だけど大丈夫なの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            なにも準備されず、話題や練習も考えずお越し下さい、うそみたいに自然と会話ができる仕組みで
                            パーティーが楽しめるように工夫がされています。なにもいりません。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>16 参加人数はどれくらいですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            私たちの活動の趣旨は、希望の相手を用意することではありませんので、人数にこだわりがありません
                            。ただし、最低男女５名以上と会場の収容人数までの範囲で、募集しその結果で開催します。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>17 男女の人数の比率は</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            バランスよく行く会場もあれば、男性が極端に多い会場、女性が大変多い会場など様々です。
                            私たちは開催日の前日までに出欠確認をしますが、一方が大変多い場合はそのことを伝えた上で出欠確認をしております。
                            ここが商売との違いで、商売の場合はどちらか一方が多い場合、少ない人数を増やす為に人を集めて多い方に合わすと思いますが、
                            私たちはボランティアですので、何人参加されても儲けがあるわけではありません。
                            参加者数を減らすように調整しています。実際人数合わせに集められた方と真面目に相手を探されている方どおしがうまく行くことは少ないと思います。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>18 もって行くものはありますか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            顔写真入の身分証明と会費を持参してください。他はいりません。
                            全ておもてなしの気持ちで運営してますので大抵は揃っていると思います。
                            ちなみに、身分証明が無い場合や忘れた場合は残念ですがお帰り頂いております。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>19 パーティーの流れは、どんな感じ</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            一言で説明すると、食事をしながら全員と平等に話してもらう、名前は分からない、
                            相手の名前も分からない、結果発表もないという感じです。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>20 中間インプレーションとかはないのですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            場の雰囲気と流れ、多くの会場を運営してきた経験から必要と判断したタイミングで行なうことは極稀にありますが、
                            ここ２年はみなさん楽しく話をされていますので第一印象などを聞く場面はやってません。
                            やりたいとは思いますが、見合いパーティーなら、必要とは思いますが、私たちは偶然な出会いと、
                            自然な出会いを提供しているのであまり重要とは思っておりません。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>21 話し好きですがういたりしないかな</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            話好きな方や話題が豊富な方、行動力がありリーダーにいつのまにかなってしまう方などどちらの会場にはいらっしゃいます。
                            皆さんが良いイメージは持たれることはあっても嫌がられることはないようですね。
                            その人の自然な姿ですので遠慮する方が不自然です。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>22 お店は、なにを基準に決めるの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            とりあえず雰囲気が良い場所で食べてみて、おいしかったところで、今後一人でも仲間どおしでもデートでも使うことが出来る場所を選んでお願いしております。
                            よくある話ですが、どうしてあのお店ではしないの？とか言われるお店があるのですが、大抵は行って話をしていますが、私たちの活動と県内の若者が抱える課題、
                            それと商売を天秤にかけるとなかなか理解や協力が得られないのが現状です。きっと各会場の方々は商売度外視で、
                            あれだけの料理をだされ、私たちと同じ志を持たれて協力してくれていると感じています。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>23 平日は、なぜ開催してないのですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            過去に何度か募集しましたが、参加申し込みに偏りがあり、最低開催人数にも達しないことが何度もあったため、
                            平日は不可能なことであると感じています。事実１度も開催できていません。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>24 どうして、ボランティアで開催しているの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            この日本にボランティアで活動している人が、こんな時代にもいることを知ってもらいたいというのと、カップリングパーティーで、
                            稼ごうと思うと、料理を無くし、女性参加費用を無料に近づけたり、呼び込みをして人数合わせたり、会費や通信料、成約料や
                            、紹介料などと巨額のお金を一人ひとりから取らないと成り立たない商売だと思います。
                            事実、商売のところに参加された方よりお話を聞くと５０万から２００万くらいは結婚する相手も見つかってないのに払わされているようです。
                            商売の邪魔はできませんが、山口県で若者の出会う機会や場所、イベントがあるかというと、夏にどこかで年に１度きりあるイベントしかなく、
                            そのイベントを逃すと１年間何もないのが山口県です。商売でやっているところにいくら払うか分かりませんがお金を払って出会いの場を探さ
                            ないといけない状況です。誰かがやらないと出会いの場は継続されません。だからボランティアでやってます。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>25 やまぐち結婚応援団とは</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            本来の目的は、結婚関連を本業にしない、会社や団体が、山口県の若者の晩婚化、少子化対策の一環として応援できないかということで、結婚に絡まない志高い方々が地域の課題解決運動の為、
                            山口県庁健康福祉部こども未来課へ申請し、審査を経て登録された団体等がやまぐち結婚応援団となっています。
                            商売で結婚斡旋やパーティーを開催している企業が登録でできないのは、金銭面などで公平性が保てないからだと思います。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>26 相手の方とどんな話しをしたらいいの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            相手のことを質問するのが一番良い話の展開だと思います。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel25'} onChange={handleChange('panel25')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>27 カップルは、毎回できるの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            カップルは継続して成立していますね。私たちのように継続してパーティーを開催していると分かることは、人数が多いとカップル成功率は下がります。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel26'} onChange={handleChange('panel26')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>28 パーティーの最中、写真やビデオを撮るのはなぜ</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            一言で言いますと防犯のためが主です。本人であることは受付で確認しますが、会場内での本人を確認する為、
                            撮影しています。今までどこかに個人が掲載されたことも、流出したこともありません。なんらかの事故等が起きたとき、
                            警察や諸官庁に提出する為に撮影しています。このことでパーティー中の安全性も、
                            手荷物の預かりに関しても安心して楽しめるための手段です。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel27'} onChange={handleChange('panel27')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>29 食事の時、どのように異性に話しかけたらよいですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            皆さんは、ご自身の名前や職業、住所や年齢が相手にはわかりませんことや、今までの経験や普段の自分など、
                            パーティー参加者には始めての出会いばかりです。そのことに気がついて頂ければ、思いっきり私らしさを出され、
                            同じ募集の広告を見て同じ不安や期待感をもって、集まっているのですから、思いと目的は同じと思います。
                            同じ思いを持っているなら時間を惜しんででも話し出すことをお勧めします。先に書きましたがその日はおもいっきり自分が出せる日です。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel28'} onChange={handleChange('panel28')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>30 また、参加してもよいですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            これが、伝えたい重要ポイント。私たちのパーティーは会場が違えども中身は紙からボード、ペンや流れまで全く一緒です。
                            どの会場に行っても同じです。同じなのであれば１度目で練習し２度目でコネタを混ぜて、
                            ３度目で今後このパーティーが進行していくかを知っているのですからうまく同じワンパターンであることを利用してください。
                            私たちはそこを利用して欲しくて同じ事を繰り返しているのですから。当然、何度も参加されれば同じ人に出会うと思います。
                            気にされずに、偶然集っただけのことなので、前に会いましたねで良いですし、前は話が途中でしたねでも良いと思いますし、
                            誰かいい人いた？と異性と話しても良いのではないでしょうか？
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel29'} onChange={handleChange('panel29')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>31 なぜ、ゲームとかはないの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            主催者は、何十年も前から当時ねるとんと言われていたパーティーなどにも参加し、
                            意味に分からないゲームや、カップルの表彰、面白くないフリータイム、
                            大した食べ物もないのに時間が長いなど多くを経験して今を運営しています。
                            参加者は社会的にも経済的にも様々な分野で活躍されている大人です。
                            偶然の出会いが目的の私たちにとって、大人の方々を意味の分からないゲームなどで誘導したりするのは失礼極まりないと思っています。
                            主催者や司会者は優位な立場ではいけないとおもってます。応援するのがボランティア団体の目的です。だから大人の方々に価値ある時間を過ごしてもらう為、
                            ゲームなどはしません。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel30'} onChange={handleChange('panel30')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>32 料金は、いつ払えばいいですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            当日の受付時に現金でお支払い下さい。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel31'} onChange={handleChange('panel31')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>33 彼女・彼氏探しでなくても参加できますか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            お友達からで良いと思いますし、たった１度参加して最高の出会いがあれば良いですが、少ない時間で相手を知り尽くすには時間が少ないと思います。ゆっくり、あえてゆっくりお友達から始められたら如何でしょう。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel32'} onChange={handleChange('panel32')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>34 なぜ免許証確認するの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            参加申し込み時に嘘の名前や住所を書いていて、当日違う名前の人が現れた場合、
                            この人が犯罪を犯さない確立はとても低いとおもうのです。恥ずかしいからとか、試しに申し込んで見るために嘘の名前を書かれたり、
                            嘘の連絡先を書く方に真剣に話をする必要は無いと思いますので、本人確認が重要となっております。
                            私たちは受付した時点で私たちが責任を持って、その方を参加者として応援するので信用したいわけです。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel33'} onChange={handleChange('panel33')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>35 どのような職業の人が多いですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            受付時点ではほとんど分かりませんし、興味がありません。ただスタッフと仲良くなる内に話すと、
                            男性は会社勤務の方や製造業、学校関係の方、女性は医療関係や学校関係、会社員が多いようです。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel34'} onChange={handleChange('panel34')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>36 その場で結果がわかるの</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            カップルになった二人のみが、その場で当事者だけが分かります。他の人は誰がカップルになったかも分かりませんし縁がなかった方も
                            、縁がなかったことが周りの人にも分かりません。不思議な仕組みですが、
                            主催者の今まで多くのパーティーに参加して経験したことの集大成がここにあります
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel35'} onChange={handleChange('panel35')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>37 らくよりは、いつから始めたのですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            平成１９年の１２月が初めての募集開始です、第１回目は平成２０年の１月が最初です。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel36'} onChange={handleChange('panel36')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>38 いままで、何回、開いていますか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            覚えられません、異常な数を開催したことは覚えています。山口県庁のホームページから私たちの名前を探されると
                            今までのパーティーが掲載してあります。数えるのが嫌になるぐらいの数です。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel37'} onChange={handleChange('panel37')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>39 らくよりで結婚された方は、いますか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            県内より多数の報告を受けています。この報告は私たちからすると感謝感激とありえない報告です。
                            私たちのパーティーの特徴は、出会ってカップルになったら後は一切関わりません。結婚されても報告はいりません。
                            県のイベントに参加したら偶然その場で出会ったというなりそめで十分で、
                            私たちの存在は消して構わないと思っていましたので、カップルになられた方から声を掛けてもらって初め
                            て結婚することが分かったぐらいです。参加者からの情報ではあの時カップルになった二人がどこかの結婚式場の
                            イベントに参加していたとか、どこかで食事していたとか聞くことがありますが、あえて
                            私たちに報告する義務はありません。でも教えてくれると活動の励みになったのは事実です。
                            だれかがやらねばという思いだけで継続している私たちに結果の報告があるのはうれしいかぎりです。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel38'} onChange={handleChange('panel38')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>40 本当に、県公認なのですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            県庁と調整しながら内容を報告し、参加者からの質問なども県と一緒になって対応しています。県が公に認めてることを公認と言います。
                            さらに平成26年度においては、日本政府より本活動の表彰を受けました。当団体の活動が評価され、内閣府特命大臣表彰を受賞しました。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel39'} onChange={handleChange('panel39')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>41 都合で途中、退室はできますか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            できません、というよりやめてください。途中退席する予定でしたらキャンセルして頂きたい。
                            一番最悪と感じる態度が途中退席や携帯メールです。相手が真剣に話をしているときにメールをされたら相手の
                            不快感ははかりしえないものです。いざ話そうと思うときに相手が退席したときの嫌な気持ちは、
                            自己中心的過ぎて私たちおもてなしの気持ちでボランティアでやっている者にとっては最悪の事態で、
                            商売でない以上徹底して苦情をその方に言っております。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel40'} onChange={handleChange('panel40')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>42 開催時間に間に合わなくても参加可能ですか</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            間に合わなくても参加は可能ですが、前日に遅れることを伝えて下さい。また当日道に迷わないよう事前に調べてもらいたいのですが、
                            万が一迷った場合などで遅れる場合も、開催３分前には連絡を入れておりますので遅れる時間を教えて下さい。
                            数分なら待ちますし、かなり遅れる場合は、食事がなくなっていたり、会話に溶け込みにくいという面もありますが、
                            遅れたネタを利用されて注目を集めることも一工夫で面白いとは感じました。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel40'} onChange={handleChange('panel40')}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                                <div style={{display: "flex", flexFlow: "column"}}>
                                    <Typography>43 らくよりドットコムの「らくより」ってどんな意味があるのですか？</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                            「らくよりと言葉に意味がありませんが、目的があり、例えばある会場で「結婚相談所主催　
                            カップリングパーティ―」と書いてあったり、案内看板が出ていた場合や、とある会場で「出会い応援パーティー」
                            とか書いてあると、通行人や近くに居る人など動物園の動物を見るように興味本位で覗いたりすると思うのです。
                            私たちは自らの団体名に価値を感じておりません。山口県で活躍されている男女の若者は見せ物ではありませんし、
                            尊重し若者の日々の活躍を応援する為に活動しています。だからこそ当団体の名前は無価値であって良い。
                            ホテルのロビーに「らくより会場２階」と書いてあっても当事者しかパーティーがあることが分かりませんし、
                            通行人や周囲の人はなんだろう？としか思わないのが私たちの意図する目的です。
                            参加して頂く方々へのご不安を少しでも減らしたい思いから、「らくより」と言う意味不明な言葉を使用しています。
                            </Typography>
                        </AccordionDetails>
                        </Accordion>
                    </div>
                    <Sidebar />
                </div>
            </div>
        </div>
    )
}

export default Questions;