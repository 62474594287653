import React, {useState, useCallback, useEffect} from "react";
import { MenuItem, Select, TextField, FormControl } from "@mui/material";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAuth } from "../authcontext";
import FooterSp from "../components/footersp";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState("");
    const { signIn } = useAuth()
    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    }, [setEmail])
    const inputPassword = useCallback((event) => {
        setPassword(event.target.value)
    }, [setPassword])
    const handleSubmit = async() => {
        try{
            await signIn(email, password)
            navigate('/profile')
        } catch(error) {
            alert("失敗")
        }
    }
    useEffect(() => {
        onAuthStateChanged(auth, (currentuser) => {
            setUser(currentuser);
        })

    }, [])
    return(
        <>
        {user ? (
            <Navigate to={"/profile"} />
        ) : (
        <div className="singup_root">
        <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                <div className="midle_space_module" />
                <div className="signup_form">
                    <h2>やまらくログイン</h2>
                    <div className="small_space_module" />
                    <div className="signup_field">
                        <div className="small_space_module" />
                        <div className="field_input">
                            <p>メールアドレス/ログインID</p>
                            <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={email} onChange={inputEmail} />
                        </div>
                        <div className="small_space_module" />
                        <div className="field_input">
                            <p>パスワード</p>
                            <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={password} onChange={inputPassword} />
                        </div>
                        <div className="large_space_module" />
                        <div className="small_space_module" />
                        <div className="to_signup">
                            <p onClick={() => navigate('/signup')}>アカウントをお持ちでない方はこちら</p>
                        </div>
                        <div className="small_space_module" />
                        <div className="singin_button">
                            <button onClick={handleSubmit}>ログイン</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="midle_space_module" />
            <div className="large_space_module" />
            <div className="large_space_module" />
            <Footer />
            <FooterSp />
        </div>
        )
        }
        </>
    )
}

export default Login;