import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../img/rakuyori_logo.png"
import { Card, Divider, Drawer, IconButton, List, Toolbar, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';

const drawerWidth = 240;
const RakuyoriHeader = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }
    const handleDrawerClose = () => {
        setIsOpen(false);
      };
    return(
        <header>
            <div className="header_top">
                <div className="header_top_left">
                    <img onClick={() => navigate('/rakuyori_top')} src={Logo} />
                </div>
            </div>
            <nav className="rakuyori_menu">
                <ul>
                    <li onClick={() => navigate('/rakuyori_concept')}>コンセプト</li>
                    <li onClick={() => navigate('/rakuyori_biginers')}>初めてご参加の方へ</li>
                    <li onClick={() => navigate('/rakuyori_questions')}>よくある質問</li>
                    <li onClick={() => navigate('/signup')}>予約する</li>
                    <li onClick={() => navigate('/policy')}>利用規約</li>
                    <li>お問い合わせ</li>
                </ul>
            </nav>
            <nav className="rakuyori_menu_icon">
                <ul>
                    <IconButton onClick={toggleMenu}>
                        <MenuIcon  />
                    </IconButton>
                </ul>                
            </nav>
            <Drawer 
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                    },
                  }}
                open={isOpen} anchor="right">
                <Toolbar>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronRightIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/rakuyori_concept')}>
                            <ListItemIcon>
                                <ListItemText primary={"コンセプト"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/rakuyori_biginers')}>
                            <ListItemIcon>
                                <ListItemText primary={"初めてご参加の方へ"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/rakuyori_questions')}>
                            <ListItemIcon>
                                <ListItemText primary={"よくある質問"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/signup')}>
                            <ListItemIcon>
                                <ListItemText primary={"予約する"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/policy')}>
                            <ListItemIcon>
                                <ListItemText primary={"利用規約"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/eventlist')}>
                            <ListItemIcon>
                                <ListItemText primary={"お問い合わせ"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </header>
    )
}

export default RakuyoriHeader;