import React, { useState, useEffect, useCallback } from "react";
import Appbar from "../components/appbar";
import InputForm from "../components/form/inputform";
import ConfirmPage from "../components/form/confirmpage";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";
import SelectEvent from "../components/form/selectevent";
import { collection, getDocs, onSnapshot, query, Timestamp, where } from "firebase/firestore";
import { db } from "../firebase";
import Complete from "../components/form/complete";

const SignUp = () => {
    const [page, setPage] = useState(0)
    const [events, setEvents] = useState([
        {id: "", name: ""}
    ]);
    const [evetnName, setEventName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [tel, setTel] = useState(
        {tel1: ""},
        {tel2: ""},
        {tel3: ""},
    )
    const [emergencyTel, setEmergencyTel] = useState(
        {tel1: ""},
        {tel2: ""},
        {tel3: ""},
    )
    const [gender, setGender] = useState("")
    const [postcode, setPostCode] = useState("")
    const [prefecture, setPrefecture] = useState("")
    const [address, setAddress] = useState("")
    const [address2, setAddress2] = useState("")
    const [date, setDate] = useState(new Date)
    useEffect(() => {
        const eventRef = collection(db, 'events')
        const q = query(eventRef, where('deadline', '>=', Timestamp.now()))
        onSnapshot(q, (snapshots) => {
            snapshots.docs.map((doc) => {
                setEvents(prev => [...prev, {
                    id: doc.id,
                    name: doc.data().title
                }])
            })
        })
    }, [])
    const componentList = [
        <SelectEvent page={page} setPage={setPage} events={events} eventName={evetnName} setEventName={setEventName} />,
        <InputForm 
            page={page} setPage={setPage} 
            firstName={firstName} setFirstName={setFirstName} 
            lastName={lastName} setLastName={setLastName} 
            email={email} setEmail={setEmail}
            password={password} setPassword={setPassword}
            tel={tel} setTel={setTel}
            emergencyTel={emergencyTel} setEmergencyTel={setEmergencyTel}
            gender={gender} setGender={setGender}
            postcode={postcode} setPostCode={setPostCode}
            prefecture={prefecture} setPrefecture={setPrefecture}
            address={address} setAddress={setAddress}
            address2={address2} setAddress2={setAddress2}
            date={date} setDate={setDate}
        />,
        <ConfirmPage
            page={page} setPage={setPage}
            firstName={firstName} lastName={lastName} email={email} password={password}
            tel={tel} emergencyTel={emergencyTel} gender={gender} postcode={postcode}
            prefecture={prefecture} address={address} address2={address2} eventName={evetnName} date={date}
        />,
        <Complete />
    ]
    return(
        <div className="singup_root">
            <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                {/* <div className="toApp">
                    <button className="">アプリをインストール</button>
                </div> */}
                {/* <div className="small_space_module" />
                <h2>当イベントはやまらくアプリをインストールしてご応募頂けます。</h2>
                <div className="small_space_module" />
                <div>
                    <div className="app_button">
                        <p>iphoneの方はこちらから</p>
                        <div className="small_space_module" />
                        <a href="https://apps.apple.com/jp/app/%E3%82%84%E3%81%BE%E3%82%89%E3%81%8F/id6444627774">
                            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&releaseDate=1464739200&h=b488c8877a1de0c0ee752e2cea473eec" alt="Download on the App Store" />
                        </a>
                    </div>
                    <div className="small_space_module" />
                    <div className="app_button">
                        <p>Androidの方はこちらから</p>
                        <a href="https://play.google.com/store/apps/details?id=com.yamaraku.rakuyori_dotto_com">
                            <img alt="Google Play で手に入れよう" src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png" />
                        </a>
                    </div>
                </div> */}
                {componentList[page]}
            </div>
            <div className="midle_space_module" />
            <Footer />
            <FooterSp />
        </div>
    )
}

export default SignUp;