import { Card, Divider, Drawer, IconButton, List, Toolbar, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState } from "react";
import Logo from "../img/logo2.png"
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';

const drawerWidth = 240;
const Appbar = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }
    const handleDrawerClose = () => {
        setIsOpen(false);
      };
    return(
        <Card className="appbar">
            <div className="app_right">
                <img onClick={() => navigate('/')} src={Logo} />
                <p onClick={() => navigate('/rakuyori_top')}>(旧 らくよりドットコムHP)</p>
            </div>
            <nav>
                <ul>
                    <li style={{color: "#D57474"}} onClick={() => navigate('/signup')}>参加予約する</li>
                    <li onClick={() => navigate('/eventlist')}>イベント情報</li>
                    {/* <li onClick={() => navigate('/login')}>ログイン</li> */}
                    {/* <li onClick={() => navigate('/profile')}>プロフィール作成</li> */}
                    <li onClick={() => navigate('/app_info')}>アプリ登録手順</li>
                    <li onClick={() => navigate('/contact')}>お問い合わせ</li>
                    <IconButton onClick={toggleMenu}>
                        <MenuIcon  />
                    </IconButton>
                </ul>
            </nav>
            <Drawer 
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                    },
                  }}
                open={isOpen} anchor="right">
                <Toolbar>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronRightIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/signup')}>
                            <ListItemIcon>
                                <InboxIcon />
                                <ListItemText primary={"参加予約する"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/eventlist')}>
                            <ListItemIcon>
                                <InboxIcon />
                                <ListItemText primary={"イベント情報"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem>
                        <ListItemButton onClick={() => navigate('/login')}>
                            <ListItemIcon>
                                <InboxIcon />
                                <ListItemText primary={"ログイン"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}
                    {/* <ListItem>
                        <ListItemButton onClick={() => navigate('/profile')}>
                            <ListItemIcon>
                                <InboxIcon />
                                <ListItemText primary={"プロフィール作成"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem> */}
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/app_info')}>
                            <ListItemIcon>
                                <InboxIcon />
                                <ListItemText primary={"アプリ登録手順"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('/contact')}>
                            <ListItemIcon>
                                <InboxIcon />
                                <ListItemText primary={"お問い合わせ"} />
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </Card>
    )
}

export default Appbar;