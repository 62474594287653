import React, { useEffect, useState } from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useAuth } from "../../authcontext";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase";
import { doc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { init , send } from "emailjs-com"

const ConfirmPage = ({page, setPage, firstName, lastName, email, password, tel, emergencyTel, gender, postcode, prefecture, address, address2, eventName, date}) => {
    const navigate = useNavigate();
    const {signUp} = useAuth() 
    const [event, setEvent] = useState(null);
    const datetimeToString = (dt) => {
        return ('00' + (dt.getMonth()+1)).slice(-2) + '月'
            + ('00' + dt.getDate()).slice(-2) + '日'
    };
    useEffect(() => {
        const eventRef = doc(db, 'events', eventName);
        getDoc(eventRef).then(
            snapshot => {
                const data = snapshot.data();
                setEvent(data)
            }
        )
    }, [])

    const handleSubmit = async(e) => {
        const userID = "CXPTie1_QaByPcBwq";
        const serviceID = "service_hlik6pg";
        const templateID = "template_tutur5f";    
        const birthday = date.toLocaleDateString();
        const toMailTel = tel.tel1 + '-' + tel.tel2 + '-' + tel.tel3
        const open = datetimeToString(event.openTime.toDate())
        try{
            return await createUserWithEmailAndPassword(auth, email, password).then(result => {
                const user = result.user;
                const uid = user.uid
                if(user) {
                    const userDocumentRef = doc(db, 'users', uid);
                    setDoc(userDocumentRef, { //アカウント作成
                        age: new Date().getFullYear() - date.getFullYear(),
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        tel: tel,
                        emergencyTel, emergencyTel,
                        gender: gender,
                        postcode: postcode,
                        prefecture: prefecture,
                        address: address,
                        address2: address2,
                        date: date,
                        uid: uid,
                        createdTime: Timestamp.now(),
                        updatedTime: Timestamp.now(),
                        imagePath: "",
                        imagePathList: [],
                        fcmToken: "",
                        events: [
                            {eventName: event.title, id: eventName, join: false},
                        ]
                    }).then(() => {
                        const applications = doc(db, 'users', uid, 'applications', eventName);
                        const eventRef = doc(db, 'events', eventName, 'applicationList', uid)
                        if(gender === "男性") {
                            setDoc(applications, { //男性応募者
                                address: prefecture + address + address2,
                                applicationTime: Timestamp.now(),
                                cancel: false,
                                charge: event.charge.chargeMen,
                                date: date,
                                deadline: event.deadline,
                                email: email,
                                emergencyTel, emergencyTel,
                                eventName: event.title,
                                eventPath: event.images[0]['path'],
                                // fcmToken: "",
                                firstName: firstName,
                                gender: gender,
                                imagePath: "",
                                lastName: lastName,
                                openTime: event.openTime,
                                participant: false,
                                postcode: postcode,
                                tel: tel,
                            }).then(() => {
                                setDoc(eventRef, {
                                    age: new Date().getFullYear() - date.getFullYear(),
                                    address: prefecture + address + address2,
                                    applicationTime: Timestamp.now(),
                                    cancel: false,
                                    charge: event.charge.chargeMen,
                                    date: date,
                                    email: email,
                                    eventName: event.title,
                                    // fcmToken: fcmToken,
                                    firstName: firstName,
                                    gender: gender,
                                    imagePath: "",
                                    lastName: lastName,
                                    permission: false,
                                    postcode: postcode,
                                    tel: tel,
                                })
                            })
                        } else { //女性応募者
                            setDoc(applications, {
                                address: prefecture + address + address2,
                                applicationTime: Timestamp.now(),
                                cancel: false,
                                charge: event.charge.chargeWomen,
                                date: date,
                                deadline: event.deadline,
                                email: email,
                                emergencyTel, emergencyTel,
                                eventName: event.title,
                                eventPath: event.images[0]['path'],
                                // fcmToken: "",
                                firstName: firstName,
                                gender: gender,
                                imagePath: "",
                                lastName: lastName,
                                openTime: event.openTime,
                                participant: false,
                                postcode: postcode,
                                tel: tel,
                            }).then(() => {
                                setDoc(eventRef, {
                                    age: new Date().getFullYear() - date.getFullYear(),
                                    address: prefecture + address + address2,
                                    applicationTime: Timestamp.now(),
                                    cancel: false,
                                    charge: event.charge.chargeWomen,
                                    date: date,
                                    email: email,
                                    eventName: event.title,
                                    // fcmToken: fcmToken,
                                    firstName: firstName,
                                    gender: gender,
                                    imagePath: "",
                                    lastName: lastName,
                                    permission: false,
                                    postcode: postcode,
                                    tel: tel,
                                })
                            })
                        }
                    }).then(() => { //メール送信
                        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
                            init(userID);
                            const template_param = {
                                eventName: event.title,
                                firstName: firstName,
                                lastName: lastName,      
                                gender: gender,
                                email: email, 
                                postcode: postcode,
                                prefecture: prefecture,
                                address: prefecture + address + address2,
                                tel: toMailTel,
                                place: event.place,
                                date: birthday,     
                                openTime: open,  
                            }; 
                            send(serviceID, templateID, template_param)
                           .catch(() => {
                                alert('送信に失敗しました')
                            })                        
                        }   
                    })
                }
            }).then(() => {
                setPage(page+1)
            })
        } catch {
            alert("イベントの応募に失敗しました。ご入力いただいたメールアドレスは既に使われている可能性があります。")
        }
    }
    return(
        <div>
        <div className="signup_form">
            <h2>やまらく会員登録(参加登録)</h2>
                <div className="small_space_module" />
                <div className="step_menu">
                    <div className="step_menu2">
                        <p>会員情報入力</p>
                    </div>
                    <div>
                        <ArrowRightIcon />
                    </div>
                    <div className="step_menu2">
                        <p>イベント選択</p>
                    </div>
                    <div>
                        <ArrowRightIcon />
                    </div>
                    <div className="step_menu1">
                        <p>確認画面</p>
                    </div>
                        <ArrowRightIcon />
                    <div className="step_menu2">
                        <p>完了画面</p>
                    </div>
                </div>
            </div>
            <div className="small_space_module" />
            <div className="confirm_form">
                <h2>会員登録情報</h2>
                <table>
                    {event && (
                        <tr>
                            <th>イベント名</th>
                            <tr>{datetimeToString(event.openTime.toDate()) + event.title}</tr>
                        </tr>
                    )}
                    <tr>
                        <th>氏名</th>
                        <tr>{firstName + lastName}</tr>
                    </tr>
                    <tr>
                        <th>性別</th>
                        <tr>{gender}</tr>
                    </tr>
                    <tr>
                        <th>生年月日</th>
                        <tr>{date.toLocaleDateString()}</tr>
                    </tr>
                    <tr>
                        <th>郵便番号</th>
                        <tr>〒{postcode}</tr>
                    </tr>
                    <tr>
                        <th>住所</th>
                        <tr>{prefecture + address + address2}</tr>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <tr>{tel.tel1  + "-" +  tel.tel2  + "-" +  tel.tel3}</tr>
                    </tr>
                    <tr>
                        <th>緊急連絡先</th>
                        <tr>{emergencyTel.tel1 + "-" + emergencyTel.tel2 + "-" + emergencyTel.tel3}</tr>
                    </tr>
                    <tr>
                        <th>メールアドレス/ログインID</th>
                        <tr>{email}</tr>
                    </tr>
                    <tr>
                        <th>パスワード</th>
                        <tr>{password}</tr>
                    </tr>
                </table>
                <div className="large_space_module" />
                <div className="input_button">
                    <button onClick={() => handleSubmit()}>会員登録</button>
                </div>
                <div className="small_space_module" />
                <h3 style={{fontSize: 14, color: "blue", textDecoration: "underline", cursor: "pointer"}} onClick={() => setPage(page-1)}>戻る</h3>
            </div>
        </div>
    )
}

export default ConfirmPage;