import { Card } from "@mui/material";
import React from "react";
import Appbar from "../components/appbar";
import Loading from "../img/loading.png"
import Logo from "../img/logo.png"
import Home from "../img/login.png"
import Profile from "../img/profile.png"
import Footer from "../components/footer";
import FooterSp from "../components/footersp";

const AppPage = () => {
    return(
        <div className="app">
            <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                {/* <div className="toApp">
                    <button className="">アプリをインストール</button>
                </div> */}
                <div className="app_root">
                    <h2>婚活イベントアプリ</h2>
                    <div className="small_space_module" />
                    <img src={Logo} />
                    <div className="midle_space_module" />
                    <div className="app_phone">
                        <img src={Loading} />
                        <img src={Home} />
                        <img src={Profile} />
                    </div>
                    <div className="midle_space_module" />
                    <p>婚活イベントの情報配信、参加、受付など<br />
                    婚活イベントアプリ<span>やまらく</span>で完結します
                    </p>
                </div>
            </div>
            <div className="midle_space_module" />
            <Footer />
            <FooterSp />
        </div>
    )
}

export default AppPage;