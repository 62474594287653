import { collection, onSnapshot, query, Timestamp, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";
import ImageSwiper from "../components/image/imageSwiper";
import { db } from "../firebase";

const TopPage = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const eventRef = collection(db, 'events');
        const q = query(eventRef, where('openTime', '>=', Timestamp.now()))
        onSnapshot(q, (snapshots) => {
            setEvents(
                snapshots.docs.map((doc) => ({...doc.data(), id: doc.id}))
            )
        })
    }, [])
    return(
        <div className="singup_root">
            <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                {/* <div className="toApp">
                    <button className="">アプリをインストール</button>
                </div> */}
                {events.length > 0 && (
                    events.map((event) => <ImageSwiper images={event.images}  />)
                )}
            </div>
            <Footer />
            <FooterSp />
        </div>
    )
}

export default TopPage;