import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

const EventSelectPro = (props) => {
    return(
        <FormControl>
            <Select
             required={props.required} value={props.value} onChange={(event) => props.select(event.target.value)} 
             variant="outlined" className="select_field"  defaultValue={props.options[0]} 
            >
                {props.options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default EventSelectPro;