import React from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EventSelectPro from "../EventSelectPro";

const SelectEvent = ({page, setPage, events, eventName, setEventName }) => {
    return(
        <div className="signup_form">
        <h2>やまらく会員登録(参加登録)</h2>
        <div className="small_space_module" />
        <div className="step_menu">
            <div className="step_menu1">
                <p>イベント選択</p>
            </div>
            <div>
                <ArrowRightIcon />
            </div>
            <div className="step_menu2">
                <p>会員情報入力</p>
            </div>
            <div>
                <ArrowRightIcon />
            </div>
            <div className="step_menu2">
                <p>確認画面</p>
            </div>
                <ArrowRightIcon />
            <div className="step_menu2">
                <p>完了画面</p>
            </div>
        </div>
        <div className="small_space_module" />
        <div className="signup_field">
            <h3>イベント選択</h3>
            <div className="small_space_module" />
            <EventSelectPro options={events} value={eventName} select={setEventName}  />
            <div className="small_space_module" />
            <div className="input_button">
            {eventName.length > 0 ?
                <button className="abled_button" onClick={() => setPage(page+1)}>次に進む</button>
                :
                <button className="disabled_button" disabled onClick={() => setPage(page+1)}>次に進む</button>
            }
                <div className="small_space_module" />
            </div>
        </div>
    </div>
    )
}

export default SelectEvent;