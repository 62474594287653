import React from "react";
import RakuyoriHeader from "../../components/rakuyoriheader";
import TopImage from "../../img/top_image.jpeg"
import News1 from "../../img/news1.jpeg"
import News2 from "../../img/news2.png"
import List1 from "../../img/list1.jpeg"
import List2 from "../../img/list2.png"
import List3 from "../../img/list3.jpeg"
import RakuyoriFooter from "../../components/rakuyorifooter";
import { useNavigate } from "react-router-dom";

const Top = () => {
    const navigate = useNavigate()
    return(
        <div className="rakuyori_back">
            <div className="main">
                <RakuyoriHeader />
                <div className="top">
                    <img src={TopImage} />
                </div>
                <div className="rakuyori_top">
                    <div className="concept">
                        <h2>カップリングパーティーで真剣な出会いを</h2>
                        <p>今、若者の集う場や施設、お見合いをする機会、出会いを勧める方々の減少、就業時間や、雇用形態の自由化による若者どおしの交流の機会が、大幅に減少しています。
                        昔ながらの地域ぐるみでの付き合いが激的に減少しているなか、地元への定住に対する理由も少なくなっています地域で育ち、地域で働く若者が、地域での出会いが不足している現状に対し、誰かが行動を起こさなければなりません。
                        私たちは、楽しいパーティーや、格好の良いパーティーはできません。とっても不器用なパーティーかも知れません。
                        でも、最大限努力し、参加される方々に多くの人へ出会って頂き、何度でも参加して、そして素敵な出会いを果たして頂きたい。
                        私たちは、各地域で、あなたと一緒に過ごす方に巡り合う機会を提供します。地元に密着した若者への出会いを応援します。</p>
                    </div>
                    <div className="schedule">
                        <p>スケジュール一覧</p>
                    </div>
                    <div className="news">
                        <div className="news_1">
                            <p>平成２６年１１月１９日に総理大臣官邸で安倍首相と有村少子化担当大臣（内閣特命大臣）表彰を受賞しました。</p>
                            <a href="http://www8.cao.go.jp/youth/ikusei/support/h26/index.html">
                                <img src={News1} />
                            </a>
                            <a href="http://www8.cao.go.jp/youth/ikusei/support/h26/index.html">
                                詳細は、内閣府HP等団体掲載記事へ
                            </a>
                        </div>
                        <div className="news_1">
                            <img onClick={() => navigate('/rakuyori_googdnews')} src={News2} />
                        </div>
                        <div className="news_2">
                            <p>「山口県では「やまぐち子育て県民運動」の一環として「やまぐち結婚応援団」の取り組みを開始し、民間による結婚に向けた出会いの場づくりを促進しています。事業の趣旨に沿い、出会いの場づくり事業を実施している団体等を「やまぐち結婚応援団」としています。</p>
                            <div className="news_2_list1">
                                <p>2010.09.27 やまぐち県民活動きらめき財団の採択事業に選ばれました。</p>
                                <img src={List1} />
                            </div>
                            <div className="news_2_list1">
                                <p>2013.06.10　らくよりドットコムは美祢市ハッピーウエディング支援事業の協力団体になりました。</p>
                                <img src={List2} />
                            </div>
                            <div className="news_2_list1">
                                <p>2015/10/29　山口県健康福祉部こども・子育て応援局こども政策課少子化対策推進班内
                                    やまぐち結婚応縁センター運営委員会より「幸せおいでませ！結婚応縁事業イベント企画運営業務」のプロポーザルにて選定されました。</p>
                                    <img src={List3} />
                            </div>
                        </div>
                    </div>
                </div>
                <RakuyoriFooter />
            </div>
        </div>
    )
}

export default Top;