import { createContext, useState, useContext, useEffect } from "react";
import { auth, db } from "./firebase";
import { addDoc, doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({children}) {
    const [user, setUser] = useState('');

    function signUp(email, password, firstName, lastName, tel, emergencyTel, gender, postcode, address, date, eventName) {
        return createUserWithEmailAndPassword(auth, email, password)
            .then(result => {
                const user = result.user;
                const uid = user.uid;
                if(user) {
                    const userDocumentRef = doc(db, 'users', uid);
                    setDoc(userDocumentRef, {
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                        tel: tel,
                        emergencyTel, emergencyTel,
                        gender: gender,
                        postcode: postcode,
                        address: address,
                        date: date,
                        uid: uid,
                        created_at: serverTimestamp(),
                    }).then(() => {
                        const applications = doc(db, 'users', uid, 'applications', eventName);
                        setDoc(applications, {
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            tel: tel,
                            emergencyTel, emergencyTel,
                            gender: gender,
                            participant: false,
                            
                        })
                    })
                }
            }).catch((error) => {
                alert('アカウント登録に失敗しました。')
                throw new Error(error)
            })
    }

    function signIn(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
            .then(result => {
                const userState = result.user;

                if(!userState) {
                    alert('ユーザーIDを取得できません')
                }

                const uid = userState.uid;
                const userDocumentRef = doc(db, 'users', uid);
                return getDoc(userDocumentRef).then(snapshot => {
                    const data = snapshot.data();

                    if(!data) {
                        alert('ユーザーデータが存在しません。')
                        throw new Error('ユーザーデータが存在しません');
                    }
                })
            })
    }
    useEffect(() => {
        const unsubscribed = auth.onAuthStateChanged((user) => {
          setUser(user);
        });
        return () => {
          unsubscribed();
        };
      }, []);
    return <AuthContext.Provider value={{user,signUp, signIn}}>{children}</AuthContext.Provider>;
}