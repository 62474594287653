import React from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Complete = () => {
    return(
        <div className="signup_form">
            <h2>やまらく会員登録(参加登録)</h2>
            <div className="small_space_module" />
            <div className="step_menu">
                <div className="step_menu2">
                    <p>イベント選択</p>
                </div>
                <div>
                    <ArrowRightIcon />
                </div>
                <div className="step_menu2">
                    <p>会員情報入力</p>
                </div>
                <div>
                    <ArrowRightIcon />
                </div>
                <div className="step_menu2">
                    <p>確認画面</p>
                </div>
                <ArrowRightIcon />
                <div className="step_menu1">
                    <p>完了画面</p>
                </div>
            </div>
            <div className="small_space_module" />
            <div className="signup_field">
               <h3>応募完了いたしました</h3>
               <div className="small_space_module" />
               <p style={{textAlign: "left"}}>後日抽選結果をご登録頂いたメールアドレスにて送信いたしますので、しばらくの間お待ちください。
               何かご不明な点がございましたら、当サイトお問い合わせページにてお尋ねください。
               </p>
            </div>
        </div>
    )
}

export default Complete;