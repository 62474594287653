import React from "react";
import RakuyoriHeader from "../../components/rakuyoriheader";
import Sidebar from "../../components/sidebar";
import TopImage from "../../img/concept_top.jpeg"

const Concept = () => {
    return(
        <div className="rakuyori_back">
            <div className="main">
                <RakuyoriHeader />
                <div className="top">
                    <img src={TopImage} />
                </div>
                <div className="concept_body">
                    <div className="concept_intro">
                        <h2>コンセプト</h2>
                        <div className="concept_list">
                            <h3>人の出会いとはとても尊いものだと私共は考えています。</h3>
                            <p>世界には何億という人がいますが、「私は人生でいったいそのうちの何人に出会うことができるのでしょうか？」
                                通りすがるだけの人もいれば、通りすがることもない人たちもたくさんいる中で、
                                同じ場所、同じ時間に出会い、話しができるというのはとても確率の少ない中の奇跡的な出来事です。
                                私の人生に何かしら意味があるからその人と出会うのだ！
                                この人は私にとってどのような良い影響を与えてくれるのだろう？・・・
                                と考えると出会いとは奥深いものだと感じます。
                            </p>
                        </div>
                        <div className="concept_list">
                            <h3>人との出会い・・・まさに一期一会です。</h3>
                            <p>らくより〜rakuyori.com〜では、そんな人の出会いを求められる方々を応援いたします。
                                恋人との出会いだけでなく、友人としての出会いも多く作っていただきたいと考えていますので、
                                カップリングパーティー・お見合いパーティーだけにとどまらず、さまざまなパーティーを開催いたします。
                            </p>
                        </div>
                        <div className="concept_list">
                            <h3>自然な出会いを・・・。</h3>
                            <p>らくより〜rakuyori.com〜では、不自然な・故意的なカップリングは行いません。
                                無理やりなことをせず、みなさまのやり取りの中で自然と出会いが作れるような、
                                カップリングパーティー・出会いの場をご提供させていただきます。
                            </p>
                        </div>
                        <div className="concept_list">
                            <h3>安心のために・・・。</h3>
                            <p>らくより〜rakuyori.com〜では、お客様に安心してご参加いただけるよう、
                                男女関わらず参加者様の身分証明書（免許証など）による本人確認を実施いたしています。
                                もし、身分証明するものが無かったり、ご本人と判断出来ない場合はカップリングパーティーのご参加をお断りする場合がございます。
                                また、代理の方のご参加もお断り致しておりますのでご了承ください。より安心のため何卒ご協力をお願い申し上げます。
                            </p>
                        </div>
                    </div>
                   <Sidebar />
                </div>
            </div>
        </div>
    )
}

export default Concept;