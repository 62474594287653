import { Button, TextField } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";
import { auth, db } from "../firebase";

const Profile = () => {
    const [profile, setProfile] = useState(null)
    const [user, setUser] = useState('')
    const [answer1, setAnswer1] = useState('')
    const [answer2, setAnswer2] = useState('')
    const [answer3, setAnswer3] = useState('')
    const [answer4, setAnswer4] = useState('')
    const [answer5, setAnswer5] = useState('')
    const [answer6, setAnswer6] = useState('')
    const [answer7, setAnswer7] = useState('')
    const inputAnswer1 = useCallback((e) => {
        setAnswer1(e.target.value)
    }, [setAnswer1])
    const inputAnswer2 = useCallback((e) => {
        setAnswer2(e.target.value)
    }, [setAnswer2])
    const inputAnswer3 = useCallback((e) => {
        setAnswer3(e.target.value)
    }, [setAnswer3])
    const inputAnswer4 = useCallback((e) => {
        setAnswer4(e.target.value)
    }, [setAnswer4])
    const inputAnswer5 = useCallback((e) => {
        setAnswer5(e.target.value)
    }, [setAnswer5])
    const inputAnswer6 = useCallback((e) => {
        setAnswer6(e.target.value)
    }, [setAnswer6])
    const inputAnswer7 = useCallback((e) => {
        setAnswer7(e.target.value)
    }, [setAnswer7])
    const uid = auth.currentUser?.uid 
    const navigate = useNavigate()
    let questions = []
    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        })
    }, [])
    useEffect(() => {
        const profileRef = doc(db, 'events', "BfDv19DKKXFe91QpQCC5", "profile", "BfDv19DKKXFe91QpQCC5");
        getDoc(profileRef).then(
            snapshot => {
                const data = snapshot.data();
                setProfile(data)
            }
        )
    }, [])
    const handleSubmit = () => {
        questions.push(profile.questions.map((question) => question.question))
        const userRef = doc(db, 'users', uid, 'applications', "BfDv19DKKXFe91QpQCC5")
        const eventRef = doc(db, 'events', "BfDv19DKKXFe91QpQCC5", 'userList', uid)
        updateDoc(userRef, {
            profile: [
                {"answer": answer1, "checked": true, "question": "仕事(職種）"}, 
                {"answer": answer2, "checked": true, "question": "趣味"}, 
                {"answer": answer3, "checked": true, "question": "最近気になる話題や、ニュースは？"}, 
                {"answer": answer4, "checked": true, "question": "最近見た映画や、好きなテレビ番組は？"}, 
                {"answer": answer5, "checked": true, "question": "お勧めのお店や場所を教えて！"}, 
                {"answer": answer6, "checked": true, "question": "休日の過ごし方"}, 
                {"answer": answer7, "checked": true, "question": "一度は行ってみたい！または、もう一度行きたいと思う場所は?"}, 
            ]
        }).then(() => {
            updateDoc(eventRef, {
                profile: [
                    {"answer": answer1, "checked": true, "question": "仕事(職種）"}, 
                    {"answer": answer2, "checked": true, "question": "趣味"}, 
                    {"answer": answer3, "checked": true, "question": "最近気になる話題や、ニュースは？"}, 
                    {"answer": answer4, "checked": true, "question": "最近見た映画や、好きなテレビ番組は？"}, 
                    {"answer": answer5, "checked": true, "question": "お勧めのお店や場所を教えて！"}, 
                    {"answer": answer6, "checked": true, "question": "休日の過ごし方"}, 
                    {"answer": answer7, "checked": true, "question": "一度は行ってみたい！または、もう一度行きたいと思う場所は?"}, 
                ]
            })
        }).then(() => {
            navigate('/')
        })
        console.log(questions)
    }

    const signout = () => {
        auth.signOut().then(() => {
            navigate('/login')
        });
    }
    return(
        <>
        <div>
            <div className="small_space_module" />
            <Appbar />
            <div className="large_space_module" />
            <div className="profile_sheet">
                <div>
                    <h2 style={{fontSize: 16}}>仕事（職種）</h2>
                    <TextField value={answer1} onChange={inputAnswer1} style={{width: "100%"}} />
                    <div className="small_space_module" />
                </div>
                <div>
                    <h2 style={{fontSize: 16}}>趣味</h2>
                    <TextField value={answer2} onChange={inputAnswer2} style={{width: "100%"}} />
                    <div className="small_space_module" />
                </div>
                <div>
                    <h2 style={{fontSize: 16}}>最近気になる話題や、ニュースは？</h2>
                    <TextField value={answer3} onChange={inputAnswer3} style={{width: "100%"}} />
                    <div className="small_space_module" />
                </div>
                <div>
                    <h2 style={{fontSize: 16}}>最近見た映画や、好きなテレビ番組は？</h2>
                    <TextField value={answer4} onChange={inputAnswer4} style={{width: "100%"}} />
                    <div className="small_space_module" />
                </div>
                <div>
                    <h2 style={{fontSize: 16}}>お勧めのお店や場所を教えて！</h2>
                    <TextField value={answer5} onChange={inputAnswer5} style={{width: "100%"}} />
                    <div className="small_space_module" />
                </div>
                <div>
                    <h2 style={{fontSize: 16}}>休日の過ごし方</h2>
                    <TextField value={answer6} onChange={inputAnswer6} style={{width: "100%"}} />
                    <div className="small_space_module" />
                </div>
                <div>
                    <h2 style={{fontSize: 16}}>一度は行ってみたい！または、もう一度行きたいと思う場所は</h2>
                    <TextField value={answer7} onChange={inputAnswer7} style={{width: "100%"}} />
                    <div className="small_space_module" />
                </div>
            </div>
            <div className="profile_button">
                <button onClick={handleSubmit}>プロフィール作成</button>
            </div>
            {/* <Button onClick={signout}>サインアウト</Button> */}
            <div className="large_space_module" />
            <Footer />
            <FooterSp />
        </div>
        </>
    )
}

export default Profile;