import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from 'swiper'; 
import React from "react";
import 'swiper/css';
import 'swiper/css/navigation'; 
import 'swiper/css/pagination';

const ImageSwiper = (props) => {
    const images = props.images
    const prevRef = React.useRef(null)
    const nextRef = React.useRef(null)
    return (
        <Swiper 
            style={{width: 151, height: 100}} 
            modules={[Navigation, Pagination]} 
            navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
            }} 
            pagination={{clickable: true}}
        >
            {images.length === 0 ? (
                <div>
                </div>
                ):(
                    images.map(image => (
                        <>
                        <SwiperSlide>
                            <div className="swiperImage" onClick={() => props.delete(image.id)}>
                                <img  src={image.path} alt="画像" />
                            </div>
                        </SwiperSlide>
                        <div ref={prevRef}>Prev</div>
                        <div ref={nextRef}>Next</div>
                        </>
                    ))
            )}
        </Swiper>
    )
}

export default ImageSwiper