import { Card, CardMedia } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const EventListItem = (props) => {
    const navigate = useNavigate();
    const datetimeToString = (dt) => {
        return dt.getFullYear() + '年'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '月'
            + ('00' + dt.getDate()).slice(-2) + '日'
    };
    const event = props.event;
    const openTime = datetimeToString(event.openTime.toDate());
    const deadline = datetimeToString(event.deadline.toDate());

    return(
        <>
        <div className="mediaCard">
            <img 
                src={event.images[0].path}
                onClick={() => navigate('/eventdetail/' + event.id)}
            />
            <div className="super_small_space_module" />
            <p className="event_title">{event.title}</p>
            <div className="event_content" style={{display: "flex"}}>
                <p>{openTime}</p>
                <div style={{width: 10}} />
                <p>{event.startTime.hour}:{event.startTime.minute} ~ {event.finishTime.hour}:{event.finishTime.minute}</p>
            </div>
            <div className="event_content">
                <p>主催者：{event.organizer}</p>
                <p>{event.content}</p>
            </div>
            <p onClick={() => navigate('/eventdetail/' + event.id)} className="mediaButton">詳細を見る &gt;</p>
        </div>
        <div style={{width: 100}} />
        </>
    )
}

export default EventListItem;