import React, { useState, useEffect, useCallback, useRef } from "react";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";

const Company = () => {
    return(
        <div className="company_detail">
            <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                {/* <div className="toApp">
                    <button className="">アプリをインストール</button>
                </div> */}
            </div>
            <div className="small_space_module" />
            <div className="company_info">
                <table>
                    <tr>
                        <th>主催者概要</th>
                        <td>社団法人やまぐち定住促進県民活動ネットワーク</td>
                    </tr>
                    <tr>
                        <th>住所</th>
                        <td>山口県山陽小野田市大字西高泊2241-3</td>
                    </tr>
                    <tr>
                        <th>事務所住所</th>
                        <td>山口県山陽小野田市日の出1丁目3番9号</td>
                    </tr>
                </table>
                <div className="midle_space_module" />
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.603658831851!2d131.1805654151616!3d34.00271272778623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354383c87e78c6a3%3A0xec0198f5329171a2!2z44CSNzU2LTAwOTEg5bGx5Y-j55yM5bGx6Zm95bCP6YeO55Sw5biC5pel44Gu5Ye677yR5LiB55uu77yT4oiS77yZIDPnlao55Y-3!5e0!3m2!1sja!2sjp!4v1668135198002!5m2!1sja!2sjp"  style={{border: 0, width: "100%", height: "100%"}} allowFullScreen={true} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className="large_space_module" />
            <Footer />
            <FooterSp />
        </div>
    )
}

export default Company;