import { InputTwoTone } from "@mui/icons-material";
import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";
import { init , send } from "emailjs-com"
import { useNavigate } from "react-router-dom";

const Contact = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [tel, setTel] = useState("")
    const [content, setContent] = useState("")

    const inputName = useCallback((e) => {
        setName(e.target.value)
    }, [setName])
    const inputEmail = useCallback((e) => {
        setEmail(e.target.value)
    }, [setEmail])
    const inputTel = useCallback((e) => {
        setTel(e.target.value)
    }, [setTel])
    const inputContent = useCallback((e) => {
        setContent(e.target.value)
    }, [setContent])

    const sendMail = () => {        
        const userID = "CXPTie1_QaByPcBwq";
        const serviceID = "service_hlik6pg";
        const templateID = "template_nv7io6t";        

        if(userID !== undefined && serviceID !== undefined && templateID !== undefined) {
            init(userID);
            const template_param = {
                name: name,
                content: content,        
                email: email,  
                tel: tel,
                content: content            
            }; 

            if(email === "") {
                alert("必須項目が未入力です")
                return false
            }

            send(serviceID, templateID, template_param)
            .then(() => {                
                navigate('/contact_complete')
            }).catch(() => {
                alert('送信に失敗しました')
            })                        
        }        
    }
    return(
        <div className="company_detail">
            <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                {/* <div className="toApp">
                    <button className="">アプリをインストール</button>
                </div> */}
            </div>
            <div className="small_space_module" />
            <div className="content">
                <h3>お問い合わせ</h3>
                <div className="small_space_module" />
                <table>
                    <tr>
                        <th>氏名</th>
                        <td><input value={name} onChange={inputName} placeholder="山田太郎" /></td>
                    </tr>
                    <tr>
                        <th>メールアドレス</th>
                        <td><input value={email} onChange={inputEmail} placeholder="example@email.com" /></td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td><input value={tel} onChange={inputTel} placeholder="000-0000-0000"/></td>
                    </tr>
                    <tr>
                        <th>質問内容</th>
                        <td><textarea rows={5} value={content} onChange={inputContent}  placeholder="質問内容"/></td>
                    </tr>
                </table>
                <div className="small_space_module" />
                <button onClick={sendMail}>送信</button>
            </div>
            <div className="large_space_module" />
            <Footer />
            <FooterSp />
        </div>
    )
}

export default Contact;