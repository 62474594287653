import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { firebaseConfig } from "./firebase";
import { getMessaging, getToken } from "firebase/messaging";
import { getFunctions } from "firebase/functions"

const app = initializeApp(firebaseConfig);

export const msg = getMessaging(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const auth = getAuth();
export const storage = getStorage(app)
export async function getFCMToken() {
    try{
        const token = await getToken(msg, {vapidKey: "BBw1ZDXgWqijY2u6291XRxsSk0BtemQmxBBly_lak2JVBHmFdi40hFJSwJgBkdEQR0hLzbcZMFEAOgygA4YnXfs"});
        return token;
    } catch(e) {
        console.log('getTokenError', e)
        return undefined
    }
}

