import React, {useCallback, useState} from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { MenuItem, Select, TextField, FormControl } from "@mui/material";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import axios from "axios";
import { Link } from "react-router-dom";

const InputForm = ({page, setPage, firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, tel, setTel, emergencyTel, setEmergencyTel, gender, setGender, postcode, setPostCode, prefecture, setPrefecture, address, setAddress, address2, setAddress2, date, setDate}) => {
    const [isCheck, setIsCheck] = useState(false);
    const inputFirstName = useCallback((event) => {
        setFirstName(event.target.value)
    }, [setFirstName])
    const inputLastName = useCallback((event) => {
        setLastName(event.target.value)
    }, [setLastName])
    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    }, [setEmail])
    const inputPassword = useCallback((event) => {
        setPassword(event.target.value)
    }, [setPassword])
    const inputTel1 = (event) => {
        setTel({...tel, tel1: event.target.value})
    }
    const inputTel2 = (event) => {
        setTel({...tel, tel2: event.target.value})
    }
    const inputTel3 = (event) => {
        setTel({...tel, tel3: event.target.value})
    }
    const inputEmergencyTel1 = (event) => {
        setEmergencyTel({...emergencyTel, tel1: event.target.value})
    }
    const inputEmergencyTel2 = (event) => {
        setEmergencyTel({...emergencyTel, tel2: event.target.value})
    }
    const inputEmergencyTel3 = (event) => {
        setEmergencyTel({...emergencyTel, tel3: event.target.value})
    }
    const inputPrefecture = useCallback((event) => {
        setPrefecture(event.target.value)
    }, [setPrefecture])
    const inputAddress = useCallback((event) => {
        setAddress(event.target.value)
    }, [setAddress])
    const inputAddress2 = useCallback((event) => {
        setAddress2(event.target.value)
    }, [setAddress2])
    const handleChange = (event) => {
      setGender(event.target.value);
    };
    const toggleCheck = (event) => {
        setIsCheck(event.target.checked)
    }
    const handleChangeRes = async(e) => {
        setPostCode(e.target.value)
        const res = await axios.get("https://api.zipaddress.net/?zipcode=" + e.target.value);
        if(res.data.code === 200) {
            setPrefecture(res.data.data.pref);
            setAddress(res.data.data.city);
            setAddress2(res.data.data.town)
        }
    }
    return(
        <div className="signup_form">
        <h2>やまらく会員登録(参加登録)</h2>
        <div className="small_space_module" />
        <div className="step_menu">
            <div className="step_menu2">
                <p>イベント選択</p>
            </div>
            <div>
                <ArrowRightIcon />
            </div>
            <div className="step_menu1">
                <p>会員情報入力</p>
            </div>
            <div>
                <ArrowRightIcon />
            </div>
            <div className="step_menu2">
                <p>確認画面</p>
            </div>
                <ArrowRightIcon />
            <div className="step_menu2">
                <p>完了画面</p>
            </div>
        </div>
        <div className="small_space_module" />
        <div className="signup_field">
            <div className="field_input">
                <p>氏名</p>
                <div className="field_flex">
                    <div className="field_input">
                        <p>姓</p>
                        <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={firstName} onChange={inputFirstName} />
                    </div>
                    <div className="small_space_module_w" />
                    <div className="field_input">
                        <p>名</p>
                        <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={lastName} onChange={inputLastName} />
                    </div>
                </div>
            </div>
            <div className="small_space_module" />
            <div className="field_input">
                <p>性別</p>
                <div className="field_flex">
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 290 }}>
                        <Select 
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={gender}
                            onChange={handleChange}
                        >
                            <MenuItem value={"男性"}>男性</MenuItem>
                            <MenuItem value={"女性"}>女性</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="small_space_module" />
            <div className="field_input">
                <p>生年月日</p>
                <div>
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                    value={date}
                    onChange={(date) => {
                        setDate(date)
                    }}
                    inputFormat='yyyy年MM月dd日'
                    mask='____年__月__日'
                    renderInput={(params) => <TextField id="standard-basic" variant="standard" {...params} />}
                />
                </LocalizationProvider>
                </div>
                {(new Date().getFullYear() - date.getFullYear() < 20) ? (
                    <p style={{color: "red", fontSize: 14}}>20歳未満の方はご利用いただけません</p>
                ): <p></p>}
            </div>
            <div className="small_space_module" />
            <div className="field_input">
                <p>所在地</p>
                <p>郵便番号</p>
                <TextField id="standard-basic" variant="standard" placeholder="入力してください" value={postcode} onChange={handleChangeRes} />
            </div>
            <div className="field_input">
                <p>都道府県</p>
                <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={prefecture} onChange={inputPrefecture} />
            </div>
            <div className="field_input">
                <p>市町村</p>
                <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={address} onChange={inputAddress} />
            </div>
            <div className="field_input">
                <p>番地以降</p>
                <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={address2} onChange={inputAddress2} />
            </div>
            <div className="small_space_module" />
            <div className="field_input">
                <p>電話番号(自宅または携帯番号)</p>
                <div className="field_input_tel">
                    <TextField style={{width: "30%"}} id="standard-basic" variant="outlined" placeholder="000" value={tel.tel1} onChange={inputTel1} />
                    <div className="super_small_space_module_w" />
                    <span>-</span>
                    <div className="super_small_space_module_w" />
                    <TextField style={{width: "35%"}} id="standard-basic" variant="outlined" placeholder="0000" value={tel.tel2} onChange={inputTel2} />
                    <div className="super_small_space_module_w" />
                    <span>-</span>
                    <div className="super_small_space_module_w" />
                    <TextField style={{width: "35%"}} id="standard-basic" variant="outlined" placeholder="0000" value={tel.tel3} onChange={inputTel3} />
                </div>
            </div>
            <div className="small_space_module" />
            <div className="field_input">
                <p>緊急用電話番号(携帯番号)</p>
                <div className="field_input_tel">
                    <TextField style={{width: "30%"}} id="standard-basic" variant="outlined" placeholder="000" value={emergencyTel.tel1} onChange={inputEmergencyTel1} />
                    <div className="super_small_space_module_w" />
                    <span>-</span>
                    <div className="super_small_space_module_w" />
                    <TextField style={{width: "35%"}} id="standard-basic" variant="outlined" placeholder="0000" value={emergencyTel.tel2} onChange={inputEmergencyTel2} />
                    <div className="super_small_space_module_w" />
                    <span>-</span>
                    <div className="super_small_space_module_w" />
                    <TextField style={{width: "35%"}} id="standard-basic" variant="outlined" placeholder="0000" value={emergencyTel.tel3} onChange={inputEmergencyTel3} />
                </div>
            </div>
            <div className="small_space_module" />
            <div className="field_input">
                <p>メールアドレス/ログインID</p>
                <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={email} onChange={inputEmail} />
            </div>
            <div className="small_space_module" />
            <div className="field_input">
                <p>パスワード(6桁以上15桁未満の半角英数)</p>
                <TextField style={{width: "100%"}} id="standard-basic" variant="standard" placeholder="入力してください" value={password} onChange={inputPassword} />
            </div>
            <div className="large_space_module" />
            <div className="large_space_module" />
            <div className="field_flex">
                <input type="checkbox" checked={isCheck} onChange={toggleCheck} />
                <p style={{fontSize: 12, textAlign: "left"}}>やまらくの<Link to={'/policy'}><span style={{color: "red"}}>利用規約およびプライバシーポリシー</span></Link>に同意します</p>
            </div>
            <div className="small_space_module" />
            <div className="input_button">
                {new Date().getFullYear() - date.getFullYear() > 18 && tel.tel1 !== "" && tel.tel2 !== "" && tel.tel3 !== "" && emergencyTel.tel1 !== "" && emergencyTel.tel2 !== "" && emergencyTel.tel3 !== "" && firstName !== "" && lastName !== "" && gender !== "" && email !== "" && postcode !== "" && address !== "" && password !== "" && isCheck === true ?
                    <button className="abled_button" onClick={() => setPage(page+1)}>次に進む</button>
                    :
                    <button disabled className="disabled_button" onClick={() => setPage(page+1)}>次に進む</button>
                }
            </div>
            <div className="small_space_module" />
            <h3 style={{fontSize: 14, color: "blue", textDecoration: "underline", cursor: "pointer"}} onClick={() => setPage(page-1)}>戻る</h3>
        </div>
    </div>
    )
}

export default InputForm;