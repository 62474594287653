import React from "react";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";

const Policy = () => {
    return(
        <div className="singup_root">
        <div className="small_space_module" />
        <Appbar />
        <div className="small_space_module" />
        <div className="home_root">
            {/* <div className="toApp">
                <button className="">アプリをインストール</button>
            </div> */}
        </div>
        <div className="midle_space_module" />
        <div className="policy_form">
            <div className="policy_sub">
                <h2>やまらく団体規約</h2>
                <p>利用規約</p>
                <p>プライバシーポリシー</p>
            </div>
            <div className="small_space_module" />
            <div className="policy_page">
                <h3>利用規約</h3>
                <div className="policy_block">
                    <h4>◯参加資格</h4>
                    <p>男性20歳以上、女性20歳以上、で独身の方に限ります。<br />
                    (パーティーによっては参加資格の設定を変更する場合がありますのでご確認のうえご予約をお願い致します)<br />
                    セールスや勧誘目的の方のご参加は堅くお断りいたします。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○パーティーの内容について</h4>
                    <p>まじめな出会いを目的としておりますので、宴会や娯楽ではありません。主催者は、出会いの場の応援
                        をすることを目的としておりますので、必ずしも相手が見つかるものや、不快になる方もいらっしゃる
                        かも知れませんが、出会いの場の提供を理解して頂きご了承ください。<br />
                        また、会費は全会場運営費として使用していますので、飲食の全てに充足したものではありません。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○パーティー参加時の身分証チェック</h4>
                    <p>皆さまに安心してパーティーに参加していただく為、男女関わらず参加者様の身分証明書(免許証など
                        顔写真のある公的身分証明書)による本人確認を実施しています。<br/>
                        参加の際は、身分証を必ずご用意くださいますようお願い申し上げます。<br />
                        もし、身分を証明するものが無かったり、ご本人と判断出来ない場合はパーティーのご参加をお断りす
                        る場合がございます。また、代理の方のご参加もお断り致しておりますのでご了承ください。<br />
                        より安心・安全のため何卒ご協力をお願い申し上げます。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○参加費用の支払い</h4>
                    <p>現金、カード、電子マネーにてお支払い頂きます。現金でお支払いのお客様はおつりが無い様にご準備の程お願い致します。<br/>
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○保険の加入について</h4>
                    <p>参加費用にはレクリエーション保険の加入料が含まれております。</p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○キャンセルについて</h4>
                    <p>参加者様は、まじめな出会いを求められている方々ですので、キャンセルされないようお願い申し上げます。</p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○パーティーの服装について</h4>
                    <p>基本的には自由とさせていただいていますが、パーティーに相応しくない過度な軽装は参加をお断りさ
                        せていただく場合がございます。パーティーの種類によっては服装を設定させていただく場合がござい
                        ますので詳細はパーティースケジュール等でご確認ください。</p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○持ち物について</h4>
                    <p>公的な身分証明書(免許証・パスポートなど顔写真が付いているもの)をご持参くださいますようお願い致します。<br />
                    本人確認の為、パーティー参加には必ず身分証明書のご提示をお願いしています。身分証明書が無い場合はパーティーへの参加は出来ませんのでご注意ください。<br />
                    また、貴重品等は各自で責任を持って管理されてください。パーティー中、パーティー終了後に紛失等された場合でも、主催者では責任を負いかねますのでご了承ください。<br />
                    大きな荷物や、不審物などの持ち込み、ビデオ、カメラ等の撮影機材などの持ち込みは禁止します。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○注意事項および禁止行為</h4>
                    <p>他のお客様への迷惑行為・発言等パーティー参加に不適当と判断した場合はパーティーの強制退去、登録を抹消し、以降のパーティー参加をお断りさせていただきます。<br />
                    退去させていただいた場合でも参加費用の返金は致しません。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○パーティへの出席方法</h4>
                    <p>各会場へは、公共手段や車など参加者ご自身で来場して下さい。送迎等は一切行いません。また、会場
                        までの往復に対し主催者では責任を負いかねますのでご了承下さい。<br />
                        パーティーによってはアルコール類が出る場合がありますが、参加者において飲酒運転をする恐れが主
                        催者が予測できた場合は、飲酒の中止を求めたり、飲酒運転への対処をお願いします。<br />
                        なお過剰な飲酒の場合、しかるべき処置を主催者にてとる場合もありますのでご了承下さい。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○中止について</h4>
                    <p>天候や男女の比率、その他の理由等でパーティーの開催を中止する場合がございますので予めご了承く
                        ださい。中止になった際は主催者より事前にご連絡をさせていただきます。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○主催者の緊急連絡先:090-1018-5690</h4>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>○緊急連絡先への連絡の承諾</h4>
                    <p>本人確認や、パーティー中止やその他の緊急連絡等でお電話をさせていただく場合がございます。<br/>
                    (参加予定のパーティーに関する内容以外に使用は致しません。)
                    </p>
                </div>
            </div>
            <div className="large_space_module" />
            <div className="large_space_module" />
            <div className="policy_page">
                <h3>プライバシーポリシー</h3>
                <div className="policy_block">
                    <p>
                        やまぐち定住促進県⺠活動ネットワーク(以下、主催者と表記します。)では電子商取引推
                        進協議会の「⺠間部門における電子商取引に係る個人情報の保護に関するガイドライン」に
                        則り、個人情報保護を遵守します。ここでは、弊社の個人情報の取扱いについて、ご説明さ
                        せていただきます。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>使用内容</h4>
                    <p>・主催者業務に関わるお申込み、お問い合わせ等を利用される際に送信される「郵便番号、
                        住所、氏名、電話番号、メールアドレス」<br />
                        ・主催者サービスの提供。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>使用内容</h4>
                    <p>・主催者業務に関わるお申込み、お問い合わせ等を利用される際に送信される「郵便番号、
                        住所、氏名、電話番号、メールアドレス」<br />
                        ・主催者サービスの提供。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>使用目的の明示及び目的外使用の禁止目的について</h4>
                    <p>
                        ・パーティー申込等ご質問にご返答する為に利用します。<br />
                        ・パーティーについての情報提供、ご連絡等の宛先として利用します。<br />
                        ・主催者が開催するパーティーに関わる情報表示として、一部使用します。<br />
                        ・主催者が開催するパーティーに関わる情報表示として、お客様の同意を得た上で、一部使用する場合があります。<br />
                        ・お問い合せの返答先として利用します。<br />
                        ・使用目的として、レクリエーション保険への加入及び請求、調査等に使用します。
                    </p>
                </div>
                <div className="small_space_module" />
                <div className="policy_block">
                    <h4>目的外使用の禁止について</h4>
                    <p>
                    ・お客様から個人情報をご提供頂く場合には、あらかじめその目的を明示させて頂き、同目 的の範囲内での利用に限定します。お客様から個人情報をご提供頂く際に明示した目的の範 囲を超えてお客様の個人情報を利用する必要が生じた場合には、事前にお客様にその目的を ご連絡します。新たな目的にご同意頂けない場合には、お客様ご自身の判断により、かかる 利用を拒否することができます。<br />
                    ・個人情報を主催者に提供することを希望されない場合、お客様はご自身の判断により、個 人情報を提供しないことが可能です。<br />
                    ・お客様の個人情報の第三者への非開示・非提供、依頼をされたパーティーやお問い合せ 等、上記目的以外に個人情報を利用致しません。<br />
                    ・お客様の情報は、ご本人の同意のない限り第三者へは提供しません。<br />
                    ・犯罪行為やその予防などの為、所轄官庁より要請のあった場合は提供します。<br />
                    ・お客様の個人情報の厳重かつ適正な管理 お客様の情報は、適切且つ厳重に管理します。<br />
                    ・個人情報の紛失、誤使用を防止する為、厳重な管理をします。 お客様の情報は、適切且つ厳重に管理します。<br />
                    ・個人情報保護法を遵守し、お客様の個人情報を厳重に管理します。
                    </p>
                </div>
                <div className="small_space_module" />
            </div>
        </div>
        <div className="large_space_module" />
        <Footer />
        <FooterSp />
    </div>
    )
}

export default Policy;