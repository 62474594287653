import React from "react";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";

const ContactComplete = () => {
    return(
        <div className="company_detail">
        <div className="small_space_module" />
        <Appbar />
        <div className="small_space_module" />
        <div className="home_root">
            {/* <div className="toApp">
                <button className="">アプリをインストール</button>
            </div> */}
        </div>
        <div className="large_space_module" />
        <div className="large_space_module" />
        <div className="large_space_module" />
        <div className="small_space_module" />
        <div className="content">
        <div className="small_space_module" />
            <h3>お問い合わせを受け付けました。</h3>
            <div className="small_space_module" />
            <p style={{textAlign: "left"}}>お問い合わせいただいた内容をご入力頂いたメールアドレスにて送信いたしますので、しばらくの間お待ちください。
            お問い合わせ受付完了のご報告メールが入力されたメールアドレス宛に届いてない場合は、お手数ですがもう一度メールアドレスをご確認されて、お問合せください。
            </p>
        </div>
        <div className="large_space_module" />
        <div className="large_space_module" />
        <div className="large_space_module" />
        <div className="large_space_module" />
        <Footer />
        <FooterSp />
    </div>
    )
}

export default ContactComplete;
