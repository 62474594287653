import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
    const navigate = useNavigate()
    return(
        <div className="concept_nav">
            <button onClick={() => navigate('/rakuyori_questions')}>らくよりへの質問</button>
            <button onClick={() => navigate('/rakuyori_googdnews')}>嬉しいお知らせ</button>
            <button onClick={() => navigate('/rakuyori_concept')}>コンセプト</button>
            <button onClick={() => navigate('/rakuyori_biginers')}>初めてご参加の方へ</button>
            <button>行政・諸団体の方々へ</button>
            <button onClick={() => navigate('/signup')}>スケジュール・ご予約</button>
            <button>掲示板</button>
            <button onClick={() => navigate('/policy')}>利用規約</button>
            <button>特定商取引について</button>
            <button>主催者概要</button>
            <button>登録団体証</button>
            <button>リンク</button>
        </div>
    )
}

export default Sidebar;