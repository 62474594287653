import React from 'react';
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './reset.css'
import './rakuyori.css'
import AppPage from './template/apppage';
import Home from './template/home';
import Top from './template/rakuyori/top';
import Concept from './template/rakuyori/concept';
import Questions from './template/rakuyori/questions';
import ToBiginer from './template/rakuyori/tobiginer';
import Events from './template/events';
import EventDetail from './template/eventdetail';
import SignUp from './template/signup';
import { AuthProvider } from './authcontext';
import Policy from './template/policy';
import Login  from './template/login';
import Application from './template/applications';
import TopPage from './template/toppage';
import GoodNews from './template/rakuyori/good_news';
import Company from './template/company';
import Contact from './template/contact';
import ContactComplete from './template/contact_complete';
import AppInfo from './template/appinfo';
import Profile from './template/profile';

function App() {
  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/applicationpage'} element={<AppPage />} />
          <Route path={'/profile'} element={<Profile />} />
          <Route path={'/policy'} element={<Policy />} />
          <Route path={'/contact'} element={<Contact />} />
          <Route path={'/contact_complete'} element={<ContactComplete />} />
          <Route path={'/eventlist'} element={<Events />} />
          <Route path={'/eventdetail/:id'} element={<EventDetail />} />
          <Route path={'/company'} element={<Company />} />
          <Route path={'/rakuyori_top'} element={<Top />} />
          <Route path={'/rakuyori_concept'} element={<Concept />} />
          <Route path={'/rakuyori_questions'} element={<Questions />} />
          <Route path={'/rakuyori_biginers'} element={<ToBiginer />} />
          <Route path={'/rakuyori_googdnews'} element={<GoodNews />} />
          <Route path={'/app_info'} element={<AppInfo />} />
          <Route path={'/signup'} element={<SignUp />} />
          <Route path={'/login'} element={<Login />} />
          <Route path={'/applications'} element={<Application />} />
          <Route path={'/toppage'} element={<TopPage />} />
        </Routes>
      </HashRouter>
    </AuthProvider>
  );
}

export default App;
