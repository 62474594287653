import React, { useEffect, useState } from "react";
import Appbar from "../components/appbar";
import EventListItem from "../components/EventListItem";
import { collection, onSnapshot, query, Timestamp, where } from "firebase/firestore" 
import { db } from "../firebase"
import Footer from "../components/footer";
import FooterSp from "../components/footersp";

const Events = () => {
    const [events, setEvents] = useState([])
    useEffect(() => {
        const eventsRef = collection(db, 'events');
        const q = query(eventsRef)
        onSnapshot(q, (snapshots) => {
            setEvents(
                snapshots.docs.map((doc) => ({...doc.data(), id: doc.id}))
            )
        })
    }, [])
    return(
        <div className="eventlist_root">
            <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                {/* <div className="toApp">
                    <button className="">アプリをインストール</button>
                </div> */}
                <h2 style={{textAlign: "center"}}>イベント一覧</h2>
                <div className="small_space_module" />
                <div className="eventList">
                {events.length > 0 && (
                    events.map(event => <EventListItem event={event} key={event.id} id={event.id} />)
                )}
                </div>
            </div>
            <div className="midle_space_module" />
            <div className="midle_space_module" />
            <div className="midle_space_module" />
            <div className="midle_space_module" />
            <div className="midle_space_module" />
            <Footer />
            <FooterSp />
        </div>
    )
}

export default Events;