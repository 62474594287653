import React from "react";
import Insta from '../img/insta.png'
import FaceBook from '../img/face_book.png'
import { useNavigate } from "react-router-dom";

const FooterSp = () => {
    const navigate = useNavigate()
    return(
        <footer className="footer_sp">
            <div className="footer_sp_block">
                <div className="footer_menu">
                    <h4>Application</h4>
                        <a href="https://apps.apple.com/jp/app/%E3%82%84%E3%81%BE%E3%82%89%E3%81%8F/id6444627774">
                            <img style={{width: 100}} src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&releaseDate=1464739200&h=b488c8877a1de0c0ee752e2cea473eec" alt="Download on the App Store" />
                        </a>
                        <div style={{width: 20}} />
                        <a href="https://play.google.com/store/apps/details?id=com.yamaraku.rakuyori_dotto_com">
                            <img style={{width: 100, height: 50}}  alt="Google Play で手に入れよう" src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png" />
                        </a>
                </div>
                <div className="footer_menu">
                    <h4>News</h4>
                    <p>ニュース一覧</p>
                </div>
                <div className="footer_menu">
                    <h4>About</h4>
                    <p onClick={() => navigate('/policy')}>団体規約</p>
                    <p onClick={() => navigate('/contact')}>お問い合わせ</p>
                    <p onClick={() => navigate('/company')}>団体概要</p>
                </div>
            </div>
            <div className="footer_sp_block2">
                <div className="footer_menu">
                    <h4>Events</h4>
                    <p onClick={() => navigate('/eventlist')}>イベント一覧</p>
                    <p onClick={() => navigate('/')}>新イベント</p>
                </div>
                <div className="footer_menu">
                    <h4>SNS</h4>
                    <div style={{display: "flex"}}>
                        <a href="https://www.instagram.com/yamaguchi_rakuyori/"><img src={Insta} /></a>
                        <div style={{width: 20}} />
                        <a href="https://www.facebook.com/profile.php?id=100085591538856"><img src={FaceBook} /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterSp;