export const firebaseConfig = {
    apiKey: "AIzaSyBIbi_8WZRSGH4E6Md-1w2Jn2U3bn5OLBE",
    authDomain: "rakuyori-dottocom.firebaseapp.com",
    databaseURL: "https://rakuyori-dottocom-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "rakuyori-dottocom",
    storageBucket: "rakuyori-dottocom.appspot.com",
    messagingSenderId: "527428360120",
    appId: "1:527428360120:web:43fe4cf74b5aeb2418e14d",
    measurementId: "G-2VFXBD77SL"
}
