import React from "react";
import RakuyoriHeader from "../../components/rakuyoriheader";
import Sidebar from "../../components/sidebar";
import News1 from "../../img/news1.png"
import News2 from "../../img/news_2.png"
import News3 from "../../img/news_3.png"
import News4 from "../../img/news_4.png"
import News5 from "../../img/news_5.png"
import News6 from "../../img/news_6.png"
import News7 from "../../img/news_7.png"
import News8 from "../../img/news_8.png"
import News9 from "../../img/news_9.png"
import News10 from "../../img/news_10.png"
import News11 from "../../img/news_11.png"
import News12 from "../../img/news_12.png"
import News13 from "../../img/news_13.png"
import News14 from "../../img/news_14.png"
import List1 from "../../img/list1.jpeg"

const GoodNews = () => {
    return(
        <div className="rakuyori_back">
            <div className="main">
                <RakuyoriHeader />
                <div className="top">
                    <img src={News1} />
                </div>
                <div className="concept_body">
                    <div className="goodnews_body">
                        <h2>嬉しいお知らせ</h2>
                        <div className="goodnews1">
                            <p>2014.11.26UP</p>
                            <p>らくよりドットコムは、「子どもと家族・若者応援団表彰」の子育て・家族支援部門で内閣 府特命担当大臣表彰を受賞しました。平成26年11月19日に総理大臣官邸で執り行われた、表彰 式に出席いたしました。</p>
                            <div className="small_space_module" />
                            <img src={News2} />
                        </div>
                        <div className="midle_space_module" />
                        <div className="goodnews1">
                            <p>2013.04.25UP</p>
                            <p>結婚式に呼ばれた際の写真です。小野田会場で出会われたカップルです。<br />
                                男性 山陽小野田市在住 Fさん<br />
                                女性 下関在住 Aさん<br />
                                ご結婚され新居を山陽小野田市に建てられお子さんまで授かりました。</p>
                        </div>
                        <div className="midle_space_module" />
                        <div className="goodnews1">
                            <p>2013.04.25UP</p>
                            <p>結婚式に呼ばれた際の写真です。小野田会場で出会われたカップルです。<br />
                                男性 山陽小野田市在住 Fさん<br />
                                女性 下関在住 Aさん<br />
                                ご結婚され新居を山陽小野田市に建てられお子さんまで授かりました。
                            </p>
                            <div className="small_space_module" />
                            <div className="goodnews_img">
                                <img src={News3} />
                                <div className="small_space_module_w" />
                                <img src={News4} />
                            </div>
                        </div>
                        <div className="small_space_module" />
                        <div className="goodnews1">
                            <p>2012.05.24UP</p>
                            <p>社団法人 やまぐち定住促進県⺠運動ネットワーク(らくよりドットコム)様 <br /><br />
                            2011年11月6日「ア・ターブル光井倶楽部」の カップリングパーティーでお世話になりました。<br />
                            2012年4月5日に結婚し、 2012年4月29日に広島の「ル・クラブ・ド・マリアージュ」で 結婚披露宴を無事に終えることができました。<br /><br />
                            私たち2人は、パーティー当日はカップルにはならなかったのですが、 その後の参加者掲示板で連絡先を交換することになり 出会ってから半年あまりで結婚となりました。<br />
                            出会えたことが全てだったと2人で感謝しております。<br /><br />
                            式も終わって落ち着きましたので 写真を2枚送信します。 このまま使用していただいて大丈夫です。<br /><br />
                            カップリングパーティーについては 式の最後の謝辞において、 2人の出会いの経緯という形で 触れさせていただきました。<br />
                            これからもご活躍を期待しております。<br /></p>
                            <div className="small_space_module" />
                            <div className="goodnews_img">
                                <img src={News5} />
                                <div className="small_space_module_w" />
                                <img src={News6} />
                            </div>
                        </div>
                        <div className="small_space_module" />
                        <div className="goodnews1">
                            <p>2012.05.10UP</p>
                            <p style={{fontWeight: "bold"}}>結婚披露宴の案内が来ました!</p>
                            <div className="small_space_module" />
                            <p>平成23年11月13日 ソルポニエンテ小野田会場にてお友達カップルから発展しこの度ご結婚されました。<br />
                            当会での出会いを楽しくご親族にも話され皆様よりお願いされましたので僭越ながら乾杯の挨 拶をさせてもらうことになりました。</p>
                            <div className="small_space_module" />
                            <div className="goodnews_img">
                                <img src={News7} />
                            </div>
                        </div>
                        <div className="small_space_module" />
                        <div className="goodnews1">
                            <p>2011.10.14UP</p>
                            <p>当会で出会われ、結婚されたお二人が、 この度、熊毛郡に新築の住宅を購入されたそうです。 山口県へ定住されるとの事で、当会としましても、 とても嬉しい報告でした。</p>
                            <div className="small_space_module" />
                            <div className="goodnews_img">
                                <img src={News8} />
                            </div>
                        </div>
                        <div className="small_space_module" />
                        <div className="goodnews1">
                            <p>2010.11.8UP</p>
                            <p>らくよりで、結ばれたカップルの赤ちゃんが誕生しました。 本当におめでとうございます!!</p>
                            <div className="small_space_module" />
                            <div className="goodnews_img">
                                <img src={News9} />
                            </div>
                        </div>
                        <div className="small_space_module" />
                        <div className="goodnews1">
                            <p>2010.09.27UP</p>
                            <div className="goodnews_img_list">
                                <img src={List1} />
                            </div>
                            <p>やまぐち県民活動きらめき財団の採択事業に選ばれました。</p>
                            <div className="small_space_module" />
                        </div>
                        <div className="small_space_module" />
                        <div className="goodnews1">
                            <p>平成23年6月27日</p>
                            <p>出会った場所 平成20年6月15日のらくよりパーティー(inソルポニエンテ)<br />
                                男性宇部市在住 Fさん 36歳<br />
                                女性山口市在住 Iさん 33歳<br />
                            </p>
                            <div className="small_space_module" />
                            <div className="goodnews_img">
                                <img src={News10} />
                                <div className="small_space_module_w" />
                                <img src={News11} />
                            </div>
                        </div>
                        <div className="small_space_module" />
                        <div className="goodnews1">
                            <p>平成21年5月14日入籍</p>
                            <p>出会った場所 平成21年3月29日 【らくよりParty】防府会場『トレボーノ』シェフ自慢 の創作料理を堪能!<br />
                                男性周南市在住 石川さん35歳<br />
                                女性美祢市在住 山本さん29歳<br />
                            </p>
                            <div className="small_space_module" />
                            <div className="goodnews_img">
                                <img src={News12} />
                                <div className="small_space_module_w" />
                                <img src={News13} />
                            </div>
                        </div>
                        <div className="large_space_module" />
                        <div className="goodnews1">
                            <h2>コメント</h2>
                            <img src={News14} />
                        </div>
                        <div className="large_space_module" />
                        <div className="goodnews1">
                            <h2>らくよりスタッフからのコメント</h2>
                            <p>
                            出会ってからも、スタッフと仲良く相談しあい、この度2組の方がご夫婦となりました。カップルになった後、お二人で始めて報告に来られたときは、もう既に夫婦!って感じでとっ
                            ても温かい空気に入り込めないほどでした。<br />
                            私たちの活動は、素人が集まりスタッフとして開催しています。とっても不器用で不恰好で、みなさんにはご迷惑をかけたかもしれません。<br />
                            そんな私たちに心から向き合って頂き、ずっと一緒にすごす方に出会えて頂いたことが、大変うれしく心から喜んでいます。<br />
                            巷では多くのパーティーが商売で開かれているようです。当然そちらのパーティーの方がきれいで、楽しさも一杯かもしれません。希望通りの相手を選んでくれるかもしれません。でも私たちは自然な出会い、多くの出会いのきっかけ、地元の良さを伝えることを、今後も心がけボランティアのまま続けていきます。2組のうれしいご報告に感謝申し上げます。<br />
                            </p>
                        </div>
                    </div>
                   <Sidebar />
                </div>
            </div>
        </div>
    )
}

export default GoodNews;