import React from "react";
import { useNavigate } from "react-router-dom";

const RakuyoriFooter = () => {
    const navigate = useNavigate()
    return(
        <div>
            <div className="app_footer">
            {/* <h2>アプリをダウンロード</h2> */}
                {/* <div className="app_button">
                    <a href="https://apps.apple.com/jp/app/itunes-store/id915061235?itsct=apps_box_badge&itscg=30200" style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 83px;">
                        <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&releaseDate=1464739200&h=b488c8877a1de0c0ee752e2cea473eec" alt="Download on the App Store" style="border-radius: 13px; width: 250px; height: 83px;" />
                    </a>
                    <a href="https://play.google.com/store/#?pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img alt="Google Play で手に入れよう" src="https://play.google.com/intl/en_us/badges/images/generic/ja_badge_web_generic.png" width="250" height="83" />
                    </a>
                </div> */}
                <div className="footer_button">
                    <button onClick={() => navigate('/')}>TOPへ</button>
                </div>
            </div>
        </div>
    )
}

export default RakuyoriFooter;