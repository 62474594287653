import React from "react";
import Appbar from "../components/appbar";
import Footer from "../components/footer";
import FooterSp from "../components/footersp";
import AppImage from "../img/appinfo.png"

const AppInfo = () => {
    return(
        <div className="company_detail">
            <div className="small_space_module" />
            <Appbar />
            <img src={AppImage} />
            <Footer />
            <FooterSp />
        </div>
    )
}

export default AppInfo;