import { doc, getDoc, Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import Appbar from "../components/appbar";
import { useNavigate } from "react-router-dom";
import  Boule  from "../img/radius.png"
import Footer from "../components/footer";
import FooterSp from "../components/footersp";
import { GoogleMap, Marker, useJsApiLoader, useLoadScript } from "@react-google-maps/api";
import { useRef } from "react";
import { useCallback } from "react";
const containerStyle = {
    width: '60%',
    height: 500,
}
const options = {
    styles: containerStyle,
    zoomControl: true,
    disableDefaultUI: true,
}
const libraries = ["places"];
const EventDetail = () => {
    const path = window.location.href
    const id = path.split('/eventdetail/')[1]
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyCEBiv81fYKARpzoAf_dI_nP5jP1ngAlqY",
        libraries,
    })
    const [center, setCenter] = useState({lat: "", lng: ""});
    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, [])
    const success = data => {
        const currentPosition = {
            lat: 35.8164188797703,
            lng: 139.57796702650623,
        };
        setCenter(currentPosition);
    }
    const error = data => {
        const currentPosition = {
          lat: 35.81608428070906,
          lng: 139.57773709938652
        };
        setCenter(currentPosition);
    };
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error)
    },[])
    const navigate = useNavigate();
    const datetimeToString = (dt) => {
        return dt.getFullYear() + '年'
            + ('00' + (dt.getMonth()+1)).slice(-2) + '月'
            + ('00' + dt.getDate()).slice(-2) + '日'
    };
    const [event, setEvent] = useState(null)

    useEffect(() => {
        const eventRef = doc(db, 'events', id);
        getDoc(eventRef).then(
            snapshot => {
                const data = snapshot.data();
                setEvent(data)
            }
        )
    }, [])
    if(loadError) return "Error loading Maps"
    if(!isLoaded) return "Loading Maps"

    return (
        <div className="eventdetail_root">
            <div className="small_space_module" />
            <Appbar />
            <div className="small_space_module" />
            <div className="home_root">
                {/* <div className="toApp">
                    <button className="">アプリをインストール</button>
                </div> */}
            </div>
            {event && (
                <div className="eventdetail_body">
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div className="left_ball">
                            <div className="blue_ball_large" />
                            <div className="green_ball_large" />
                            <div className="pink_ball_small" />
                            <div className="orange_ball_small" />
                        </div>
                        <div>
                            <h2>{event.title}</h2>
                            <div className="small_space_module" />
                            <p>{event.content}</p>
                        </div>
                        <div className="right_ball">
                            <div className="green_ball_small" />
                            <div className="blue_ball_small" />
                            <div className="orange_ball_large" />
                            <div className="pink_ball_large" />
                        </div>
                    </div>
                    <img src={event.images[0].path} />
                    <div className="eventdetail_content">
                        <div className="event_table">
                            <h3>イベント情報</h3>
                            <table>
                                <tr>
                                    <th>開催予定日</th>
                                    <td>{datetimeToString(event.openTime.toDate())}</td>
                                </tr>
                                <div className="super_small_space_module" />
                                <tr>
                                    <th>開催場所</th>
                                    <td>{event.place}</td>
                                </tr>
                                <div className="super_small_space_module" />
                                <tr>
                                    <th>主催者</th>
                                    <td>{event.organizer}</td>
                                </tr>
                                <div className="super_small_space_module" />
                                <tr>
                                    <th>参加人数</th>
                                    <td>男性{event.applications.men}名　女性{event.applications.women}名</td>
                                </tr>
                                <div className="super_small_space_module" />
                                <tr>
                                    <th>参加費用</th>
                                    <td>男性{event.charge.chargeMen}円　女性{event.charge.chargeWomen}円</td>
                                </tr>
                                <div className="super_small_space_module" />
                                <tr>
                                    <th>服装</th>
                                    {event.fashions.map(fashion => (
                                        <td style={{display: "flex", flexDirection: "column"}}>{fashion}</td>
                                    ))}
                                </tr>
                                <div className="super_small_space_module" />
                                <tr>
                                    <th>その他</th>
                                    <td>{event.other}</td>
                                </tr>
                                {event.age &&  
                                    <tr>
                                        <th>年齢制限</th>
                                        <td>{event.age}</td>
                                    </tr>
                                }
                            </table>
                        </div>
                        <div className="join_event">
                            {event.deadline < Timestamp.now() ? 
                                <button>応募締切</button> : 
                                <button onClick={() => navigate('/signup')}>イベントに参加する</button>
                            }
                            <div className="super_small_space_module" />
                            <p>※イベント参加は抽選になります。<br />
                            尚、参加申込にはやまらく会員登録が必須になります。
                            </p>
                        </div>
                    </div>
                    <div className="midle_space_module" />
                    <div className="join_event_garally">
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={{
                                lat: event.lat,
                                lng: event.lng
                            }}
                            zoom={14}
                            options={options}
                            onLoad={onMapLoad}
                        >
                            <Marker position={{lat: event.lat, lng: event.lng}} />
                        </GoogleMap>
                        <div className="midle_space_module" />
                            {event.deadline < Timestamp.now() ? 
                                <button>応募締切</button> : 
                                <button onClick={() => navigate('/signup')}>イベントに参加する</button>
                            }
                            <div className="super_small_space_module" />
                            <p>※イベント参加は抽選になります。<br />
                            尚、参加申込にはやまらく会員登録が必須になります。
                            </p>
                    </div>
                </div>
            )}
                <div className="midle_space_module" />
            <Footer />
            <FooterSp />
        </div>
    )
}

export default EventDetail;